export const moodData=[
    {
        title:"Calm",
        icon:""
    },
    {
        title:"Happy",
        icon:""
    },
    {
        title:"Energetic",
        icon:""
    },
    {
        title:"Frisky",
        icon:""
    },
    {
        title:"Mood Swings",
        icon:""
    },
    {
        title:"Irritated",
        icon:""
    },
    {
        title:"Sad",
        icon:""
    },
    {
        title:"Anxious",
        icon:""
    },
    {
        title:"Depressed",
        icon:""
    },
    {
        title:"Feeling guilty",
        icon:""
    },
    {
        title:"Obsessive thoughts",
        icon:""
    },
    {
        title:"Low energy",
        icon:""
    },
    {
        title:"Apathic",
        icon:""
    },
    {
        title:"Confused",
        icon:""
    },
    {
        title:"Very self-critical",
        icon:""
    },

]
export const symptomsData=[
    {
        title:"Everything is fine",
        icon:""
    },
    {
        title:"Cramps",
        icon:""
    },
    {
        title:"Tender breasts",
        icon:""
    },
    {
        title:"Headache",
        icon:""
    },
    {
        title:"Acne",
        icon:""
    },
    {
        title:"Backache",
        icon:""
    },
    {
        title:"Fatigue",
        icon:""
    },
    {
        title:"Cravings",
        icon:""
    },
    {
        title:"Insomnia",
        icon:""
    },
    {
        title:"Abdominal pain",
        icon:""
    },
    {
        title:"Vaginal itching",
        icon:""
    },
    {
        title:"Vaginal dryness",
        icon:""
    },

]
export const vaginalDischargeData=[
    {
        title:"No discharge",
        icon:""
    },
    {
        title:"Creamy",
        icon:""
    },
    {
        title:"Watery",
        icon:""
    },
    {
        title:"Sticky",
        icon:""
    },
    {
        title:"Egg white",
        icon:""
    },
    {
        title:"Spotting",
        icon:""
    },
    {
        title:"Unusual",
        icon:""
    },
    {
        title:"Clumpy white",
        icon:""
    },
    {
        title:"Gray",
        icon:""
    },

]
export const digestionData=[
    {
        title:"Nausea",
        icon:""
    },
    {
        title:"Bloating",
        icon:""
    },
    {
        title:"Constipation",
        icon:""
    },
    {
        title:"Diarrhea",
        icon:""
    },
    

]
export const pregnancyTestData=[
    {
        title:"Didn't take tests",
        icon:""
    },
    {
        title:"Positive",
        icon:""
    },
    {
        title:"Negative",
        icon:""
    },
    {
        title:"Faint line",
        icon:""
    },

]
export const ovulationTestData=[
    {
        title:"Didn't take tests",
        icon:""
    },
    {
        title:"Ovulation(Tested)",
        icon:""
    },
    {
        title:"No Ovulation(Tested)",
        icon:""
    },

]
export const physicalActivityData=[
    {
        title:"Didn't exercise",
        icon:""
    },
    {
        title:"Yoga",
        icon:""
    },
    {
        title:"Gym",
        icon:""
    },
    {
        title:"Aerobics & dancing",
        icon:""
    },
    {
        title:"Swimming",
        icon:""
    },
    {
        title:"Team sports",
        icon:""
    },
    {
        title:"Swimming",
        icon:""
    },
    {
        title:"Running",
        icon:""
    },
    {
        title:"Cycling",
        icon:""
    },
    {
        title:"Walking",
        icon:""
    },

]
export const otherData=[
    {
        title:"Travel",
        icon:""
    },
    {
        title:"Stress",
        icon:""
    },
    {
        title:"Meditation",
        icon:""
    },
    {
        title:"Journaling",
        icon:""
    },
    {
        title:"Kegel exercises",
        icon:""
    },
    {
        title:"Breathing exercises",
        icon:""
    },
    {
        title:"Disease or injury",
        icon:""
    },
    {
        title:"Alcohol",
        icon:""
    },

]

export const sexDriveData=[
    {
        title:"Didn't have sex",
        icon:""
    },
    {
        title:"Unprotected sex",
        icon:""
    },
    {
        title:"Protected sex",
        icon:""
    },
    {
        title:"Orgasm",
        icon:""
    },
    {
        title:"High Sex Drive",
        icon:""
    },
    {
        title:"Neutral Sex Drive",
        icon:""
    },
    {
        title:"Low Sex Drive",
        icon:""
    },
]