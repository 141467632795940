import React from 'react'
import FAQ from '../Components/FAQ'
import Navbar from '../Components/Navbar'
import { Accordion } from 'flowbite-react'
import { faqs } from '../Data/commonFAQ'
export default function FAQs() {
  return (
    <div className='min-h-screen w-full  flex flex-col items-center justify-center '>
      <div className='min-h-screen w-full p-8  flex flex-col items-center justify-center '>
          <Navbar/>
          <h2 className='text-3xl md:text-5xl text-center font-bold mb-4 '>Some Tips about  <span className='text-primary-500'>Menstruation</span></h2>
          <Accordion className='w-full max-w-5xl bg-white'>
              {faqs.map((a)=>(<Accordion.Panel >
                <Accordion.Title className='text-left p-2 md:p-5 '>{a.question}</Accordion.Title>
                <Accordion.Content>
                  <p className="mb-2 p-2 md:p-5 text-left text-gray-500 dark:text-gray-400">
                   {a.answer}
                  </p>
                </Accordion.Content>
              </Accordion.Panel>))}
            </Accordion>
      </div>
      <div className='min-h-screen w-full p-8 bg-primary-50 flex flex-col items-center justify-center '>
          <Navbar/>
          <h2 className='text-3xl md:text-5xl text-center font-bold mb-4 '>How Does OMNI <span className='text-primary-500'>Work?</span></h2>
          <FAQ/>
      </div>
    </div>
  )
}
