import React from 'react'
import MDEditor from '@uiw/react-md-editor'
export default function UserChatLong({text,avatar}) {
  return (
    <div className="flex my-2">
        <img className="w-12 h-12 p-1 mr-2 rounded-full bg-gray-200" src={avatar}/>
        <div href="#" class="w-4/5  p-4 bg-primary-400 text-white border border-gray-200 rounded-lg shadow-md h dark:bg-gray-800 dark:border-gray-700 ">
            <MDEditor.Markdown className='dark:hidden text-xs' source={text} style={{ background: "transparent",color:"white",fontFamily:"Poppins" }} />
            <MDEditor.Markdown className='hidden dark:block text-xs' source={text} style={{ background: "transparent",fontFamily:"Poppins" }} />
        </div>
    </div>
  )
}
