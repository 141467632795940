import React,{useState} from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import "./calendercss.css"
import Navbar from '../Components/Navbar'

import { faqs } from '../Data/commonFAQ';
import { Accordion } from "flowbite-react";
import SymptomModal from '../Components/Calendar/SymptomModal';
export default function DateCalendarServerRequest() {
  const cycleLength=30
  const ovulationLength=15
  const [ovulationDates,setOvulationDates]=useState([])
  const [menstruationDates,setMentruationDates]=useState([])
  const [noteVal,setNoteVal]=useState("")
  const [notes,setNotes]=useState([])
  const [openPopup,setOpenPopup]=useState(false)
  const [currentDate,setCurrentDate]=useState(null)
  const getMensturationDates=()=>{
    for(let i=0;i<6;i++){
      let tempDate=new Date(localStorage.getItem("omni-cycle-date"))
      tempDate.setDate(tempDate.getDate()+cycleLength*i) //next men cycle
      setMentruationDates(menstruationDates=>[...menstruationDates,tempDate])
      // tempDate.setDate(tempDate.getDate()-15)//next ovluation
      // setOvulationDates(ovulationDates=>[...menstruationDates,tempDate])
    }
  }
  const getOvulationDates=()=>{
    for(let i=1;i<6;i++){
      let tempDate=new Date(localStorage.getItem("omni-cycle-date"))
      tempDate.setDate(tempDate.getDate()+ovulationLength*i) //next men cycle
      setOvulationDates(ovulationDates=>[...ovulationDates,tempDate])
      // tempDate.setDate(tempDate.getDate()-15)//next ovluation
      // setOvulationDates(ovulationDates=>[...menstruationDates,tempDate])
    } 
  }
  
  const addNewNote=()=>{
    setNotes(notes=>[...notes,noteVal])
    setNoteVal("")
  }
  // const getColsestMenstruation=()=>{
  //   const currentDate=Date.now()
  //   let minDistance=getDayDiff(currentDate,Date.parse(menstruationDates[0])) 
  //   let closestDate=menstruationDates[0]
  //   for(let i=1;i<menstruationDates.length;i++){
  //       let dis=getDayDiff(currentDate,menstruationDates[i])
  //       if(dis<minDistance){
  //         minDistance=dis
  //         closestDate=menstruationDates[i]
  //       }
  //   }
  //   console.log("CLOSEST : ",closestDate)
    
  // }
  React.useEffect(()=>{
    getMensturationDates()
    getOvulationDates()
    //getColsestMenstruation()
  },[])
  
   const tileContent = ({ date, view }) => {
    for(let i=0;i<menstruationDates.length;i++){
      if(date.getDate()>=menstruationDates[i].getDate() &&  date.getDate()<menstruationDates[i].getDate()+6 &&  menstruationDates[i].getMonth()==date.getMonth()){
        //console.log("MATCH")
        return (<p>🩸</p>)
      }
    }
    for(let i=0;i<ovulationDates.length;i++){
      if(date.getDate()>=ovulationDates[i].getDate() &&  date.getDate()<ovulationDates[i].getDate()+6 &&  ovulationDates[i].getMonth()==date.getMonth()){
        //console.log("MATCH")
        return (<p>🥚</p>)
      }
    }
  }
  return (
    <div className='w-screen text-center min-h-screen flex flex-col items-center justify-center'>
          <Navbar/>
          <div className='sticky md:hidden w-full p-4 text-left shadow-lg mb-4'>
            <h2 className='text-2xl font-bold text-primary-500'>Omni Calender</h2>
            <p className='text-sm'>Find your suggested products</p>
          </div>
          <div className='md:mt-24  w-4/5 p-4 text-left shadow-md rounded-md border border-gray-200 mb-4'>
          <Calendar
            tileClassName={({ activeStartDate, date, view }) =>view === 'month' && date.getDate() > 18 ? ["text-red-500","text-bold"] : ""}
            tileContent={tileContent}
            calendarType='islamic'
            showNavigation={true}
            onClickDay={(value,event)=>{
              console.log(value)
              setCurrentDate(value)
              setOpenPopup(true)
            }}
          />
          </div>
          <div className='w-4/5 mb-4 flex flex-col items-center justify-center shadow-md rounded-md border border-gray-200 p-4'>
            <p className='text-sm'>Your period is coming up in</p>
            <h2 className='text-2xl font-bold text-primary-500'>4 days</h2>
          </div>
          <div className='w-4/5 mb-4 flex flex-col items-center justify-center shadow-md rounded-md border border-gray-200 p-4'>
            <h2 className='text-2xl font-bold text-primary-500'>Add Notes</h2>
            <p className='text-sm mb-4'>Write notes on your feelings and symptoms</p>
            <textarea value={noteVal} onChange={e=>setNoteVal(e.target.value)} className='w-full p-4 border-gray-400 rounded-md'/>
            <button onClick={addNewNote} className='text-white mt-2 bg-primary-500 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800'>Add Note</button>
            {notes.map(a=>(<div className='w-full shadow-md border border-gray-100 my-2 p-2 py-4'>
              <p>{a}</p>
            </div>))}
          </div>
          <div className='w-4/5 mb-4 flex flex-col items-center justify-center shadow-md rounded-md border border-gray-200 p-4'>
            <h2 className='text-2xl font-bold text-primary-500'>Omni Tips</h2>
            <p className='text-sm mb-4'>Some tips to help you during your cycles</p>
            <Accordion className='w-full max-w-5xl bg-white'>
              {faqs.map((a)=>(<Accordion.Panel >
                <Accordion.Title className='text-left p-2 md:p-5 '>{a.question}</Accordion.Title>
                <Accordion.Content>
                  <p className="mb-2 p-2 md:p-5 text-left text-gray-500 dark:text-gray-400">
                   {a.answer}
                  </p>
                </Accordion.Content>
              </Accordion.Panel>))}
            </Accordion>
          </div>



          {/***MODAL */}
          {openPopup &&<SymptomModal setOpenPopup={setOpenPopup}/> }
          
    </div>
  );
}
