// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyA05W5ktCUBshLYpq4ao370fuh99iHb2CA",
    authDomain: "acme-ai.firebaseapp.com",
    projectId: "acme-ai",
    storageBucket: "acme-ai.appspot.com",
    messagingSenderId: "4725322863",
    appId: "1:4725322863:web:814801cd54d0eada50c99a",
    measurementId: "G-GQ1BWG0R0B"
  };
  

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app