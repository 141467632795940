export const products=[
    {
    image:"https://th.bing.com/th/id/OIP.8QVZ81PCuf0mY-6usAwKQgHaEw?rs=1&pid=ImgDetMain",
    title:"Sanitary Napkin",
    price:130,
    reason1:"I want to track my periods",
    resason2:"",
    reason3:""
    },
    {
    image:"https://th.bing.com/th/id/R.a8a4a86cecfd1901db11901b9396871a?rik=dQDJjfCZNzaOyg&pid=ImgRaw&r=0" ,
    title:"Tampons" ,
    price:120,
    reason1:"I want to track my periods",
    resason2:"",
    reason3:""
    },
    {
    image:"https://organicnutrition.com.bd/cdn/shop/files/12.jpg?v=1715400041" ,
    title:"Karkuma Superfood" ,
    price:950,
    reason1:"I want to track my periods",
    resason2:"I have some abnormal symptomps",
    reason3:"I want to conceive"
    },
    {
    image:"https://cdn01.pharmeasy.in/dam/products_otc/K58148/sanfe-reusable-menstrual-cup-with-no-rashes-leakage-or-odor-premium-design-for-women-large-2-1641790059.jpg" ,
    title:"Menstrual Cup" ,
    price:300,
    reason1:"I want to track my periods",
    resason2:"",
    reason3:""
    },
    {
    image:"https://www.chococraving.com/wp-content/uploads/2022/02/combo-pack-2.jpg" ,
    title:"Dark Chocolate" ,
    price:250,
    reason1:"I want to track my periods",
    resason2:"I have some abnormal symptomps",
    reason3:"" 
    },
    {
    image:"https://th.bing.com/th/id/OIP.cBpz4uzdEX4o775oCTtFUAHaHa?rs=1&pid=ImgDetMain" ,
    title:"Ice Cream" ,
    price:250,
    reason1:"I want to track my periods",
    resason2:"I have some abnormal symptomps",
    reason3:""
    },
    {
    image:"https://th.bing.com/th/id/OIP.SWSc5l17hRqyHTHOB4G3_wHaHa?rs=1&pid=ImgDetMain" ,
    title:"Flowers" ,
    price:320,
    reason1:"I want to track my periods",
    resason2:"",
    reason3:""
    },
    {
    image:"https://images-na.ssl-images-amazon.com/images/I/81t5xSJ8YxL.SL1500.jpg" ,
    title:"Male Condoms" ,
    price:80,
    reason1:"I don't want to become pregnant",
    resason2:"",
    reason3:""
    },
    {
    image:"https://th.bing.com/th/id/OIP.2r3vjpAvfnkAzknuV20v5AHaJ4?rs=1&pid=ImgDetMain" ,
    title:"Female Condoms" ,
    price:120,
    reason1:"I don't want to become pregnant",
    resason2:"",
    reason3:""
    },
    {
    image:"https://th.bing.com/th/id/R.1059fe61f830e90e59706b86a3e01100?rik=DaCrhBxccgu%2f8w&pid=ImgRaw&r=0" ,
    title:"Contraceptive Pills" ,
    price:25,
    reason1:"I don't want to become pregnant",
    resason2:"",
    reason3:""
    },
]