import { ArrowDownIcon, CaretDownIcon } from '@radix-ui/react-icons'
import React from 'react'
import { Accordion } from "flowbite-react";
const FAQ = () => {
  return (
    <Accordion className='w-full max-w-5xl bg-white'>
    <Accordion.Panel >
      <Accordion.Title className='text-left p-2 md:p-5 '>What is Omni?</Accordion.Title>
      <Accordion.Content>
        <p className="mb-2 p-2 md:p-5 text-gray-500 dark:text-gray-400">
          Omni is the next generation of menstruation tracking application that helps women, their partners and their parents to be able to track their cycles more efficiently.
        </p>
      </Accordion.Content>
    </Accordion.Panel>
    <Accordion.Panel>
      <Accordion.Title className='text-left p-2 md:p-8'>How does Omni help women?</Accordion.Title>
      <Accordion.Content>
        <p className="mb-2 p-2 md:p-8 text-gray-500 dark:text-gray-400">
          Omni Helps women by : 
        </p>
        <ul className="list-disc pl-5 text-gray-500 dark:text-gray-400" >
          <li>Tracking Menstrual Cycles</li>
          <li>Converating with Omni "Bondhu"</li>
          <li>Diagnosis of abnormality</li>
          <li>Direct Contact with Doctors</li>
          <li>Intuitive Notifications</li>
          <li>Purchasing of hygine products</li>
        </ul>
       
      </Accordion.Content>
    </Accordion.Panel>
    <Accordion.Panel>
      <Accordion.Title className='text-left p-2 md:p-8'>How do the partners use Omni?</Accordion.Title>
      <Accordion.Content>
      <p className="mb-2 p-2 md:p-8 text-gray-500 dark:text-gray-400">
          Omni Helps women by : 
        </p>
        <ul className="list-disc pl-5 text-gray-500 dark:text-gray-400" >
          <li>Tracking Menstrual Cycles</li>
          <li>Converating with Omni "Bondhu"</li>
          <li>Diagnosis of abnormality</li>
          <li>Direct Contact with Doctors</li>
          <li>Intuitive Notifications</li>
          <li>Purchasing of hygine products</li>
        </ul>
      </Accordion.Content>
    </Accordion.Panel>
    <Accordion.Panel>
      <Accordion.Title className='text-left p-2 md:p-8'>How do the parents use Omni?</Accordion.Title>
      <Accordion.Content>
      <p className="mb-2 p-2 md:p-8 text-gray-500 dark:text-gray-400">
          Omni Helps women by : 
        </p>
        <ul className="list-disc pl-5 text-gray-500 dark:text-gray-400" >
          <li>Tracking Menstrual Cycles</li>
          <li>Converating with Omni "Bondhu"</li>
          <li>Diagnosis of abnormality</li>
          <li>Direct Contact with Doctors</li>
          <li>Intuitive Notifications</li>
          <li>Purchasing of hygine products</li>
        </ul>
      </Accordion.Content>
    </Accordion.Panel>
    <Accordion.Panel>
      <Accordion.Title className='text-left p-2 md:p-8'>Is Omni Paid?</Accordion.Title>
      <Accordion.Content>
        <p className="mb-2 p-2 md:p-8 text-gray-500 dark:text-gray-400">
          Our goal is to cater to women all around Bangladesh. That is why we have kept our platform completely free of cost to our users! 
        </p>
      </Accordion.Content>
    </Accordion.Panel>
  </Accordion>

  )
}

export default FAQ
