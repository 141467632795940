import React, { useState,useEffect } from 'react'
import Agent from '../Components/Agent'
import Navbar from '../Components/Navbar'
import { ArrowLeftIcon, EraserIcon, InfoCircledIcon } from '@radix-ui/react-icons'
import ChatUI from '../Components/ChatUI'
import { useNavigate } from 'react-router-dom'
import { Modal,Button } from 'flowbite-react'

const ChitChat = () => {
  
  const navigator=useNavigate()
  const [operationMode,setOperationMode]=useState("friend")
  const [openModal,setOpenModal]=useState(false)
  const [hideAlert,setHideAlert]=useState(false)
  const [lan,setLan]=useState("bangla")
  const hideAlertFunc=()=>{
    setHideAlert(true)
  }
  const getLan=()=>{
      if(localStorage.getItem('omni-language')==="english"){
          setLan("english")
      }
  }
  useEffect(()=>{
    getLan()
    },[])
  return (
    <div className='min-h-screen w-screen flex flex-col items-center justify-center'>
      {window.innerWidth<600 && <Navbar/>}
      <button onClick={()=>navigator('/')} className={`fixed  top-4 left-4 bg-white hover:bg-gray-200 cursor-pointer rounded-md border border-gray-200 shadow-lg p-2 text-xl`}><ArrowLeftIcon width={22} height={22}/></button>
      <div className='w-4/5 mb-4 flex flex-col items-center justify-center shadow-md rounded-md border border-gray-200 p-4'>
      <ul class="flex mb-2 text-sm font-medium text-center text-gray-500 dark:text-gray-400">
          <li onClick={()=>setOperationMode("friend")} class="me-2 cursor-pointer">
              <a 
              className={operationMode=="friend"?"inline-block px-4 py-3 text-white bg-primary-500 rounded-lg active":"inline-block px-4 py-3 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white"} 
              >{lan=="english"?"Friend Mode":"বন্ধু মোড"}</a>
          </li>
          <li onClick={()=>setOperationMode("chat")} class="me-2 cursor-pointer">
              <a 
              className={operationMode=="chat"?"inline-block px-4 py-3 text-white bg-primary-500 rounded-lg active":"inline-block px-4 py-3 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 dark:hover:text-white"}
              >{lan=="english"?"Chat Mode":"চ্যাট মোড "}</a>
          </li>
          
      </ul>
      </div>
      {/**FRIEND */}
      {operationMode=="friend" && 
        <div className='relative w-4/5 min-h-[600px] flex flex-col items-center justify-center shadow-xl rounded-md border border-gray-200 p-8'>
          {!hideAlert && <p className='absolute top-4 left-4 w-7/8 text-sm text-gray-400'>{localStorage.getItem("omni-language")==="english"?"":`** বর্তমানে আমাদের অমনি বন্ধু শুধু ইংরেজি বুঝতে পারে। তুমি তার সাথের ইংরেজিতে কথা বলতে পারো অথবা বাংলায় চ্যাট করতে পারো  **`}</p>}
          <Agent hideAlertFunc={hideAlertFunc}/>
          
        </div>}
      {/**CHAT UI */}
      {operationMode=="chat" && <ChatUI/>}
    
      {/**Selected Lan*/}
      {localStorage.getItem("omni-language")=="english"?(<button onClick={()=>{
        setLan("bangla")
        localStorage.setItem("omni-language","bangla")
      }}  className={`fixed flex items-center top-4 right-16 bg-gray-100 cursor-pointer rounded-md border border-gray-300 shadow-lg p-2 text-xl`}><img src="https://i.postimg.cc/63QDL1YV/england-4854979.png" width={22} height={22} className='mr-2'/><p className='text-sm font-semibold'>English</p></button>):
      (<button onClick={()=>{
        setLan("english")
        localStorage.setItem("omni-language","english")
      }}  className={`fixed flex items-center top-4 right-16 bg-gray-100 cursor-pointer rounded-md border border-gray-200 shadow-lg p-2 text-xl`}><img src="https://i.postimg.cc/gkRkZnwz/bangladesh-9993444.png" width={22} height={22} className='mr-2'/><p className='text-sm font-semibold'>বাংলা </p></button>)
      }
      {/**INFO MODAL */}
      <button onClick={()=>setOpenModal(true)} className={`fixed  top-4 left-16 bg-white hover:bg-gray-200 cursor-pointer rounded-md border border-gray-300 shadow-lg p-2 text-xl`}><InfoCircledIcon width={22} height={22}/></button>
      <Modal show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header><p className='text-primary-500 font-bold'>{lan==="english"?"Your Information":"আপনার তথাবলি "}</p></Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <p className="text-base  text-black leading-relaxed  dark:text-gray-400">
            {lan==="english"?"Here are some of the information that Omni is using about you : ":"অমনি আপনার সম্পর্কে যেসব তথ্য ব্যবহার করছে : "}
             
            </p>
            {/**Role */}
            <div className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              <span className='text-md font-semibold text-black'>{lan==="english"?"Role":"ভূমিকা"}</span>
              <p>
                {localStorage.getItem("omni-user")?localStorage.getItem("omni-user"):"Not provided"}
              </p>
            </div>
            {/**Name */}
            <div className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              <span className='text-md font-semibold  text-black'>{lan==="english"?"Name":"নাম"}</span>
              <p>
                {localStorage.getItem("omni-name")?localStorage.getItem("omni-name"):"Not provided"}
              </p>
            </div>
            {/**Age */}
            <div className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              <span className='text-md font-semibold  text-black'>{lan==="english"?"Age":"বয়স"}</span>
              <p>
                {localStorage.getItem("omni-age")?localStorage.getItem("omni-age"):"Not provided"}
              </p>
            </div>
            {/**Profession */}
            <div className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              <span className='text-md font-semibold  text-black'>{lan==="english"?"Profession":"পেশা"}</span>
              <p>
                {localStorage.getItem("omni-professsion")?localStorage.getItem("omni-professsion"):"Not provided"}
              </p>
            </div>
            {/**Hobby */}
            <div className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              <span className='text-md font-semibold text-black'>{lan==="english"?"Hobby":"শখ"}</span>
              <p>
                {localStorage.getItem("omni-hobby")?localStorage.getItem("omni-hobby"):"Not provided"}
              </p>
            </div>
            {/**Usage reason */}
            <div className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              <span className='text-md font-semibold  text-black'>{lan==="english"?"Usage Reason":"ব্যবহারের কারন"}</span>
              <p>
                {localStorage.getItem("omni-reason")?localStorage.getItem("omni-reason"):"Not provided"}
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className='flex items-center justify-center' color="gray" onClick={() => navigator("/get-started")}>
            <EraserIcon className='mr-2'/>
            <p>{lan==="english"?"Edit Information":"তথ্য পরিবর্তন করুন"}</p>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ChitChat
