import React,{useState,useEffect} from 'react'
import Confetti from 'react-confetti'
import { useNavigate } from 'react-router-dom'
export default function Congratulations() {
    const navigator=useNavigate()
    const [screenHeight,setScreenHeight]=useState(window.screen.height)
    const [screenWidth,setScreenWidth]=useState(window.screen.width)
    const [lan,setLan]=useState("bangla")
    useEffect(()=>{
      if(localStorage.getItem("omni-language")=="english"){
        setLan("english")
      }
    },[])
  return (
    <div className='w-screen overflow-x-hidden h-screen flex flex-col items-center justify-center dark:bg-gray-900 p-6'>
        <Confetti/>
        <div class="z-20 flex max-w-xl flex-col items-center justify-center w-full px-2 py-12 md:p-24 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">🎉🎉 {lan==="english"?" Congratulations! ":" অভিনন্দন! "}🎉🎉</h5>
            <p class="font-normal text-center text-gray-700 dark:text-gray-400">{lan==="english"?"You have set-up Omni":"আপনার অমনি একাউন্ট সফলভাবে খুলে গেছে"}</p>
            <button onClick={()=>navigator("/chitchat")} class="inline-flex items-center mt-4 px-4 py-2 text-md font-medium text-center text-white bg-primary-500 rounded-lg hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
            {lan=="english"?"Start Your Journey":"আপনার যাত্রা শুরু করুন "}
            </button>
        </div>
        
    </div>
 

  )
}
