import './App.css';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import Home from './Pages/Home';
import ChitChat from './Pages/ChitChat';
import CalenderPage from './Pages/CalenderPage';
import GetStarted from './Pages/GetStarted';
import Congratulations from './Pages/Congratulations';
import FAQs from './Pages/FAQs';
import Shop from './Pages/Shop';
import FindDoctors from './Pages/FindDoctors';
import Tester from './Pages/Tester';
import Login from './Pages/Login';
import CreateAccount from './Pages/CreateAccount';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/create-account" element={<CreateAccount />} />
        <Route path="/congratulations" element={<Congratulations />} />
        <Route path="/calendar" element={<CalenderPage />} />
        <Route path="/get-started" element={<GetStarted />} />
        <Route path="/chitchat" element={<ChitChat />} />
        <Route path="/shop" element={<Shop />} />
        <Route path="/doctors" element={<FindDoctors />} />
        <Route path="/faq" element={<FAQs />} />
        <Route path="/tester" element={<Tester />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
