export const faqs=[
    {
        "question": "What is menstruation?",
        "answer": "Menstruation is the monthly process in which the uterus sheds its lining, resulting in blood and tissue leaving the body through the vagina. This typically lasts between 3 to 7 days."
    },
    {
        "question": "At what age do most people start menstruating?",
        "answer": "Most people start menstruating between the ages of 9 and 16, with the average age being around 12."
    },
    {
        "question": "How long does a typical menstrual cycle last?",
        "answer": "A typical menstrual cycle lasts about 28 days, but it can range from 21 to 35 days in adults and 21 to 45 days in teens."
    },
    {
        "question": "What are common symptoms experienced during menstruation?",
        "answer": "Common symptoms include cramps, bloating, mood swings, headaches, and fatigue. Some people may also experience nausea or breast tenderness."
    },
    {
        "question": "How can menstrual cramps be alleviated?",
        "answer": "Menstrual cramps can be alleviated through various methods such as taking over-the-counter pain relievers, applying heat to the lower abdomen, exercising, and staying hydrated."
    },
    {
        "question": "Is it normal to have irregular periods?",
        "answer": "It is normal for periods to be irregular, especially in the first few years after menstruation begins and during perimenopause. Factors like stress, diet, and exercise can also affect cycle regularity."
    },
    {
        "question": "What should I do if I miss a period?",
        "answer": "If you miss a period, it could be due to various reasons such as stress, hormonal changes, or pregnancy. It's advisable to take a pregnancy test if you're sexually active. If missed periods continue, consult a healthcare provider."
    },
    {
        "question": "Can I swim or exercise during my period?",
        "answer": "Yes, you can swim and exercise during your period. Using tampons or menstrual cups can make these activities more comfortable. Exercise may even help reduce menstrual symptoms like cramps."
    },
    {
        "question": "What types of menstrual products are available?",
        "answer": "There are various menstrual products available including pads, tampons, menstrual cups, and period underwear. The choice depends on personal preference and comfort."
    },
    {
        "question": "When should I see a doctor about my periods?",
        "answer": "You should see a doctor if you experience severe pain, very heavy bleeding, periods that last longer than seven days, or if your periods stop suddenly or become very irregular."
    }
]
