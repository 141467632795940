import React, { useEffect, useState } from 'react'
import Navbar from '../Components/Navbar'
import { useNavigate } from 'react-router-dom';
const GetStarted = () => {
    const navigator=useNavigate()
    const [selectedLanguage,setSelectedLanguage]=useState("bangla")
    const [selectedOption,setSelectedOption]=useState("woman")
    const [optionPage,setOptionPage]=useState(0)
    const [name,setName]=useState(null)
    const [age,setAge]=useState(null)
    const [profession,setProfession]=useState(null)
    const [hobby,setHobby]=useState(null)
    const [cycleDate,setCycleDate]=useState(null)
    const [reason,setReason]=useState("I want to track my periods")
    const [lan,setLan]=useState("bangla")
    const nextOption=()=>{
      if(optionPage>-1){
        setOptionPage(optionPage+1)
      }
    }
    const prevOption=()=>{
      if(optionPage<4){
        setOptionPage(optionPage-1)
      }
    }
    const setLanguage=()=>{
      localStorage.setItem("omni-language",selectedLanguage)
      nextOption()
    }
    const setUserOption=()=>{
      localStorage.setItem("omni-user",selectedOption)
      nextOption()
    }
    const setUserInformation=()=>{
      localStorage.setItem("omni-name",name)
      localStorage.setItem("omni-age",age)
      localStorage.setItem("omni-profession",profession)
      localStorage.setItem("omni-hobby",hobby)
      nextOption()
    }
    const setDate=()=>{
      console.log(cycleDate)
      localStorage.setItem("omni-cycle-date",cycleDate)
      nextOption()
    }
    const setUsageReason=()=>{
      localStorage.setItem("omni-reason",reason)
      navigator("/congratulations")
    }
    useEffect(()=>{
      if(localStorage.getItem("omni-language")==="english"){
        setLan("english")
      }
      setName(localStorage.getItem("omni-name"))
      setAge(localStorage.getItem("omni-age"))
      setProfession(localStorage.getItem("omni-profession"))
      setHobby(localStorage.getItem("omni-hobby"))
    },[])
    return (
      <div className='w-screen flex flex-col items-center justify-center'>
        <Navbar/>
        {optionPage==0 && <div className='w-full min-h-screen flex flex-col items-center justify-center'>
        <h2 className='text-3xl font-semibold'>{lan==="english"?"My Language is : ":"আমার পছন্দের ভাষা হচ্ছে : "}</h2>
          <div className='w-full max-w-6xl grid grid-cols-1 md:grid-cols-1 gap-2'>
            <div onClick={()=>setSelectedLanguage("bangla")} class={`flex flex-col items-center justify-center cursor-pointer  p-4 m-2 ${selectedLanguage==="bangla" ? "bg-primary-500 text-white":"bg-white text-gray-900"} border border-gray-200 rounded-lg shadow  dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700`}>
              <img src="https://i.postimg.cc/SxqLv1pN/5.png" class="dark:block w-[120px]" alt=""/>
              <h5 class="mb-2 text-lg font-semibold tracking-tight  dark:text-white">বাংলা</h5>
            </div>
            <div onClick={()=>setSelectedLanguage("english")} class={`flex flex-col items-center justify-center cursor-pointer p-4 m-2 ${selectedLanguage==="english" ? "bg-primary-500 text-white":"bg-white text-gray-900"} border border-gray-200 rounded-lg shadow  dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700`}>
              <img src="https://i.postimg.cc/Z5cPtPTP/6.png" class="dark:block w-[120px]" alt=""/>
              <h5 class="mb-2 text-lg font-semibold tracking-tight dark:text-white">English</h5>
            </div>
            
          </div>
          <div className='w-full flex items-center justify-end p-4'>
            <button onClick={setLanguage} className='text-white bg-primary-500 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800'>{lan==="english"?"Next":"পরবর্তী"}</button>
          </div>
      </div>}
        {optionPage==1 && <div className='w-full min-h-screen flex flex-col items-center justify-center'>
        <h2 className='text-3xl font-semibold'>{lan==="english"?"I want to start as a..":"আমি শুরু করতে চাই একজন ____ হিসেবে"}</h2>
          <div className='w-full max-w-6xl grid grid-cols-1 md:grid-cols-1 gap-2'>
            <div onClick={()=>setSelectedOption("woman")} class={`flex flex-col items-center justify-center cursor-pointer  p-4 m-2 ${selectedOption==="woman" ? "bg-primary-500 text-white":"bg-white text-gray-900"} border border-gray-200 rounded-lg shadow  dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700`}>
              <img src="https://i.postimg.cc/MZ9C3mLK/8.png" class="dark:block w-[120px]" alt=""/>
              <h5 class="mb-2 text-lg font-semibold tracking-tight  dark:text-white">{lan==="english"?"Woman":"নারী "}</h5>
            </div>
            <div onClick={()=>setSelectedOption("partner")} class={`flex flex-col items-center justify-center cursor-pointer p-4 m-2 ${selectedOption==="partner" ? "bg-primary-500 text-white":"bg-white text-gray-900"} border border-gray-200 rounded-lg shadow  dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700`}>
              <img src="https://i.postimg.cc/Cxf4GhwP/4.png" class="dark:block w-[120px]" alt=""/>
              <h5 class="mb-2 text-lg font-semibold tracking-tight dark:text-white">{lan==="english"?"Partner":"সঙ্গী"}</h5>
              
            </div>
            
            <a onClick={()=>setSelectedOption("parent")} class={`flex flex-col items-center justify-center cursor-pointer p-4 m-2 ${selectedOption==="parent" ? "bg-primary-500 text-white":"bg-white text-gray-900"} border border-gray-200 rounded-lg shadow  dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700`}>
              <img src="https://i.postimg.cc/GpFJfX8v/5.png" class="dark:block w-[120px]" alt=""/>
              <h5 class="mb-2 text-lg font-semibold tracking-tight dark:text-white">{lan==="english"?"Parent":"অভিভাবক"}</h5>
            
            </a>
          </div>
          <div className='w-full flex items-center justify-between p-4'>
            <button onClick={prevOption} className='text-white bg-primary-500 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800'>{lan==="english"?"Previous":"পরবর্তী"}</button>
            <button onClick={setUserOption} className='text-white bg-primary-500 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800'>{lan==="english"?"Next":"পরবর্তী"}</button>
          </div>
      </div>}
        {optionPage==2 && <div className='w-full max-w-6xl min-h-screen flex flex-col items-center justify-center'>
        <h2 className='text-3xl font-semibold'>{lan==="english"?"Some Basic Information":"কিছু সাধারণ তথ্য"}</h2>
          <div className='w-full p-8'>
            <div class="mb-4 w-full">
                <label for="default-input" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{lan==="english"?"Name":"নাম"}<span className='text-primary-500'>*</span></label>
                <input type="text" value={name} onChange={(e)=>setName(e.target.value)} id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"/>
            </div>
            <div class="mb-4 w-full">
                <label for="default-input" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{lan==="english"?"Age":"বয়স"}<span className='text-primary-500'>*</span></label>
                <input type="number" value={age} onChange={(e)=>setAge(e.target.value)} id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"/>
            </div>
            <div class="mb-4 w-full">
                <label for="default-input" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{lan==="english"?"Profession":"পেশা"}</label>
                <input type="text" value={profession} onChange={(e)=>setProfession(e.target.value)} id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"/>
            </div>
            <div class="mb-4 w-full">
                <label for="default-input" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{lan==="english"?"Hobby":"শখ"}</label>
                <input type="text" value={hobby} onChange={(e)=>setHobby(e.target.value)} id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"/>
            </div>
          </div>
          <div className='w-full flex items-center justify-between p-4'>
            <button onClick={prevOption} className='text-white bg-primary-500 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800'>{lan==="english"?"Previous":"পূর্ববর্তী "}</button>
            <button onClick={setUserInformation} className='text-white bg-primary-500 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800'>{lan==="english"?"Next":"পরবর্তী"}</button>
          </div>
      </div>}
        {optionPage==3 && <div className='w-full min-h-screen max-w-6xl flex flex-col items-center justify-center'>
        <h2 className='text-3xl font-semibold'>{lan==="english"?"Your Previous Cycle Date":"পূর্ববর্তী মাসিকের দিন"}</h2>
          <div className='w-full p-8'>
            <div class="mb-4 w-full">
                <label for="default-input" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{lan==="english"?"Prev Cycle Date":"পূর্ববর্তী মাসিকের দিন"}<span className='text-primary-500'>*</span></label>
                <input type="date" value={cycleDate} onChange={(e)=>setCycleDate(e.target.value)} id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"/>

            </div>
            
          </div>
          <div className='w-full flex items-center justify-between p-4'>
            <button onClick={prevOption} className='text-white bg-primary-500 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800'>{lan==="english"?"Previous":"পূর্ববর্তী"}</button>
            <button onClick={setDate} className='text-white bg-primary-500 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800'>{lan==="english"?"Next":"পরবর্তী"}</button>
          </div>
      </div>}
      {optionPage==4 && <div className='w-full max-w-6xl  min-h-screen flex flex-col items-center justify-center'>
        <h2 className='text-3xl font-semibold'>{lan==="english"?"I'll use omni because..":"আমি অমনি ব্যবহার করতে চাই কেননা "}</h2>
          <div className='w-full grid grid-cols-1 md:grid-cols-2 gap-2'>
            <div onClick={()=>setReason("I want to track my periods")} class={`flex flex-col items-center justify-center  p-4 m-2 ${reason==="I want to track my periods" ? "bg-primary-500 text-white":"bg-white text-gray-900"} border border-gray-200 rounded-lg shadow  dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700`}>
              <img src="https://i.postimg.cc/x1zBCD1c/3.png" class="dark:block w-[70px]" alt=""/>
              <h5 class="mb-2 text-lg font-semibold tracking-tight  dark:text-white">{lan==="english"?"I want to track my cycles":"আমি আমার মাসিক "}</h5>
            </div>
            <div onClick={()=>setReason("I have some abnormal symptomps")} class={`flex flex-col items-center justify-center  p-4 m-2 ${reason==="I have some abnormal symptomps" ? "bg-primary-500 text-white":"bg-white text-gray-900"} border border-gray-200 rounded-lg shadow  dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700`}>
              <img src="https://i.postimg.cc/WbtQBJRm/5.png" class="dark:block w-[70px]" alt=""/>
              <h5 class="mb-2 text-lg font-semibold tracking-tight dark:text-white">{lan==="english"?"I have some abnormal symptomps":"আমার অস্বাভাবিক উপসর্গ আছে "}</h5>
              
            </div>
            <div onClick={()=>setReason("I want to conceive")} class={`flex flex-col items-center justify-center  p-4 m-2 ${reason==="I want to conceive" ? "bg-primary-500 text-white":"bg-white text-gray-900"} border border-gray-200 rounded-lg shadow  dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700`}>
              <img src="https://i.postimg.cc/fTF6DTZG/7.png" class="dark:block w-[70px]" alt=""/>
              <h5 class="mb-2 text-lg font-semibold tracking-tight dark:text-white">{lan==="english"?"I want to conceive":"আমি গর্ভবতী হতে চাই"}</h5>
            </div>
            <div onClick={()=>setReason("I don't want to become pregnant")} class={`flex flex-col items-center justify-center  p-4 m-2 ${reason==="I don't want to become pregnant" ? "bg-primary-500 text-white":"bg-white text-gray-900"} border border-gray-200 rounded-lg shadow  dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700`}>
              <img src="https://i.postimg.cc/x8pBk9mb/6.png" class="dark:block w-[70px]" alt=""/>
              <h5 class="mb-2 text-lg font-semibold tracking-tight dark:text-white">{lan==="english"?"I don't want to become pregnant":"আমি গর্ভবতী হতে চাইনা"}</h5>
            </div>
          </div>
          <div className='w-full flex items-center justify-between p-4'>
            <button onClick={prevOption} className='text-white bg-primary-500 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800'>{lan==="english"?"Previous":"পূর্ববর্তী "}</button>
            <button onClick={setUsageReason} className='text-white bg-primary-500 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800'>{lan==="english"?"Get Started":"পরবর্তী"}</button>
          </div>
      </div>}
      </div>
    )
}

export default GetStarted;
