import React from 'react'
import Navbar from '../Components/Navbar'
import ProductCard from '../Components/ProductCard'
import { products } from '../Data/productData'
export default function Shop() {
    const reason=localStorage.getItem("omni-reason")
    
  return (
    <div className='w-screen min-h-screen flex flex-col items-center'>
        <Navbar/>
        <div className='sticky md:hidden w-full p-4 text-left shadow-lg mb-4'>
            <h2 className='text-2xl font-bold text-primary-500'>Omni Marketplace</h2>
            <p className='text-sm'>Find your suggested products</p>
        </div>
        <div className='w-4/5 mb-4 md:mt-28 flex flex-col items-center justify-center shadow-md rounded-md border border-gray-200 p-4'>
            <p className='text-sm'>Your period is coming up in</p>
            <h2 className='text-2xl font-bold text-primary-500'>4 days</h2>
        </div>
        <h2 className='w-4/5 text-lg font-bold text-left mb-4'>Suggested Products</h2>
        <div className='w-4/5 grid grid-cols-1 md:grid-cols-4 gap-4'>
            {products.map(a=>{
                if(a.reason1==reason || a.resason2==reason || a.reason3==reason){
                    return(<ProductCard image={a.image} title={a.title} price={a.price} />)
                }
            })}
         
        </div>
        <h2 className='w-4/5 text-lg font-bold text-left my-4'>Other Products</h2>
        <div className='w-4/5 grid grid-cols-1 md:grid-cols-4 gap-4'>
            {products.map(a=>{
                if(a.reason1!=reason && a.resason2!=reason && a.reason3!=reason){
                    return(<ProductCard image={a.image} title={a.title} price={a.price} />)
                }
            })}
        </div>
        <div className='w-4/5 mb-8'>

        </div>
    </div>
  )
}
