import React, {useState } from "react";

import { useNavigate } from "react-router-dom";
// import { UserIDContext, UserIDUpdateContext, UserTypeContext, UserTypeUpdateContext } from '../Context/UserContext'
import axios from "axios";
import { UserAuth } from "../Hooks/AuthContext";
import {  BASE_URL } from "../Data/apiData";
import { CircularProgress } from "@mui/material";
import { RiUploadCloud2Line } from "react-icons/ri";


export default function CreateAccount() {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userType,setUserType]=useState("owner")
  const [name, setName] = useState(null);
  const [shopName, setShopName] = useState(null);
  const [color, setColor] = useState(null);
  const [shopImage, setShopImage] = useState(null);

  const [uploadingImage,setUploadingImage]=useState(false)
  const [imageUploaded,setImageUploaded]=useState(false)

  const [createStage,setCreateStage]=useState(0)
  const [loggingIn, setLogggingIn] = useState(false);

  const navigator = useNavigate();

  const { createUser } = UserAuth();
  const createUsr = async () => {
    //await createUser(email,password)
    return await axios.post(`${BASE_URL}/user`, {
      name: name,
      email:email,
      type:userType
    });
  };
  const updateShopId = async (shp_id,usr_id) => {
    return await axios.put(`${BASE_URL}/user/shop_id/${shp_id}/${usr_id}`);
  };
  const createShop = async (ownr_id) => {
    //await createUser(email,password)
    return await axios.post(`${BASE_URL}/shop`, {
      name: shopName,
      image:shopImage,
      active:true,
      color:"#9c27b0",
      owner_id:ownr_id
    });
  };
  const handleSubmit = async () => {
    if(password!=confirmPassword){
      alert("Your passwords don't match")
      return
    }
    try {
      setLogggingIn(true);
      const usrRes=await createUsr()
      console.log("USER ID : ",usrRes.data.id)
      const shopRes=await createShop(usrRes.data.id)
      await updateShopId(shopRes.data.id,usrRes.data.id)
      localStorage.setItem("bebsha-store",shopRes.data.id)
    } catch (e) {
      console.log(e);
      alert("Something went wrong...");
      setLogggingIn(false);
      return
      
    } finally {
      try{
        const usr=await createUser(email, password);
        console.log(usr)

      }catch(e){
        console.log(e)
        alert("Couldn't create account with email and password")
        setLogggingIn(false);
        return
      }
      navigator("/listing")
      setLogggingIn(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
      <section class="bg-red-50  dark:bg-gray-900">
        <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <div
            className="flex h-20 items-center justify-center"
            style={{ alignItems: "center" }}
          >
            <img
              className="w-12 h-12  hidden md:flex"
              src="/logo.png"
            />
            <h2 className="text-2xl font-bold text-black font-title dark:text-gray-100">
              <span className="text-primary-500"></span>bebsha.ai
            </h2>
          </div>
          <div class="w-full bg-white noscrollbar rounded-lg shadow-lg border border-gray-100 md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700 overflow-y-scroll">
            <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Create An Account
              </h1>
              {createStage==0 && <form class="space-y-4 md:space-y-6">
                <input
                  type="text"
                  id="name"
                  class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Name"
                  required=""
                  onChange={(e) => setName(e.target.value)}
                />
                <input
                  type="email"
                  id="email"
                  class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Email Address"
                  required=""
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  type="password"
                  id="password"
                  placeholder="Password"
                  class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required=""
                  onChange={(e) => setPassword(e.target.value)}
                />
                <input
                  type="password"
                  placeholder="Confirm Password"
                  class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                </form>}
                {createStage==1 && <div>
                    <h2 className="text-left font-semibold text-lg mb-4">I want to start as </h2>
                    <div className="grid grid-cols-2 gap-4">
                      <div onClick={()=>setUserType("owner")} className={userType=="owner"?`flex cursor-pointer bg-primary-200 text-white items-center justify-center p-6 min-h-[200px] shadow-md border border-gray-200 rounded-lg`:`flex  cursor-pointer items-center justify-center p-6 min-h-[200px] shadow-md border border-gray-200 rounded-lg`}>
                          Shop Owner
                      </div>
                      <div onClick={()=>setUserType("employee")} className={userType=="employee"?`flex cursor-pointer bg-primary-200 text-white items-center justify-center p-6 min-h-[200px] shadow-md border border-gray-200 rounded-lg`:`flex cursor-pointer  items-center justify-center p-6 min-h-[200px] shadow-md border border-gray-200 rounded-lg`}>
                          Employee
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 mt-4">
                      <button onClick={()=>setCreateStage(0)} class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Prev</button>
                      <button onClick={()=>setCreateStage(2)} class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Next</button>
                    </div>
                  </div>}
                
                {createStage==3 && <div>
                    <h2 className="text-left font-semibold text-lg mb-4">Detail Summary</h2>
                     {/** Category Image Upload */}
                     <div className="max-w-sm mx-auto">
                        <label  className="block text-sm font-medium text-gray-900 dark:text-white">Shop Image</label>
                        <div className='max-w-sm mx-auto min-h-sm flex flex-col items-center rounded-lg border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 p-4'>
                            {imageUploaded && <img src={shopImage} className='w-[200px]'/>}
                        </div>
                    </div>
                    <label  className="block text-sm font-medium text-gray-900 dark:text-white">Shop Name</label>
                    <p className="pt-2 pb-4">{shopName}</p>
                    <label  className="block text-sm font-medium text-gray-900 dark:text-white">User Name</label>
                    <p className="pt-2 pb-4">{name}</p>
                    <label  className="block text-sm font-medium text-gray-900 dark:text-white">User Email</label>
                    <p className="pt-2 pb-4">{email}</p>
                    <label  className="block text-sm font-medium text-gray-900 dark:text-white">User Type</label>
                    <p className="pt-2 pb-4">{userType}</p>
                  </div>}

                {createStage==0 && !loggingIn && (<button onClick={()=>setCreateStage(1)} class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Next</button>)}
                
                {/* {createStage==1 && !loggingIn && (
                  
                )} */}
                {createStage==3 && !loggingIn && (<button onClick={()=>setCreateStage(2)} class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Go Back</button>)}
                {createStage==3 && !loggingIn && (<button onClick={handleSubmit} class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Create Account</button>)}
                {createStage==3 &&  loggingIn && (<div style={{ display: "flex", alignItems: "center" }}><CircularProgress /><h2 sx={{ marginLeft: "10px" }}> Creating Account</h2></div>)}
                {createStage==0 && <button
                  type="submit"
                  class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                  onClick={() => navigator("/")}
                >
                  View As Guest
                </button>}
                {createStage==0 && <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                  Already Have an account?{" "}
                  <a
                    onClick={() => navigator("/login")}
                    class="font-medium text-primary-600 hover:underline dark:text-primary-500"
                  >
                    Sign In
                  </a>
                </p>}
              
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
