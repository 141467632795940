export const doctors = [
  {
    name: "Dr. A K M Mafizur Rahman",

    degrees: "MBBS, DDV, MCPS",

    lat: 23.8103,

    lng: 90.4125,

    address: "Labaid Specialised Hospital, Dhanmondi, Dhaka",
  },

  {
    name: "Dr. Tahmina Banu",

    degrees: "MBBS, FCPS (Dermatology & Venereology)",

    lat: 23.7509,

    lng: 90.3928,

    address: "Square Hospital, Panthapath, Dhaka",
  },

  {
    name: "Dr. Sujit Kumar Barua",

    degrees: "MBBS, FCPS (Dermatology & Venereology)",

    lat: 23.7947,

    lng: 90.4086,

    address: "United Hospital, Gulshan, Dhaka",
  },

  {
    name: "Dr. Naila Malik",

    degrees: "MBBS, DDV",

    lat: 23.7561,

    lng: 90.3873,

    address: "Green Life Hospital, Green Road, Dhaka",
  },

  {
    name: "Dr. Asit Baran Adhikary",

    degrees: "MBBS, FCGP, DDV",

    lat: 23.7689,

    lng: 90.3886,

    address: "Ibn Sina Hospital, Dhanmondi, Dhaka",
  },

  {
    name: "Dr. Sanjida Haque",

    degrees: "MBBS, FCPS (Skin & VD)",

    lat: 23.7434,

    lng: 90.3775,

    address: "Dhaka Medical College and Hospital, Dhaka",
  },

  {
    name: "Dr. Ratul Roy",

    degrees: "MBBS, DDV",

    lat: 23.7808,

    lng: 90.4225,

    address: "Evercare Hospital, Bashundhara, Dhaka",
  },

  {
    name: "Dr. Ananya Das",

    degrees: "MBBS, FCPS (Dermatology)",

    lat: 23.7856,

    lng: 90.3794,

    address: "Central Hospital, Dhanmondi, Dhaka",
  },

  {
    name: "Dr. Rafiq Hasan",

    degrees: "MBBS, DDV",

    lat: 23.8125,

    lng: 90.4038,

    address: "Ahsania Mission Cancer Hospital, Uttara, Dhaka",
  },

  {
    name: "Dr. Sultana Begum",

    degrees: "MBBS, FCPS (Skin & VD)",

    lat: 23.7296,

    lng: 90.4114,

    address: "BRB Hospital, Panthapath, Dhaka",
  },

  {
    name: "Dr. Anisur Rahman",

    degrees: "MBBS, DDV, FCPS",

    lat: 23.7623,

    lng: 90.4265,

    address: "Apollo Diagnostic Centre, Gulshan, Dhaka",
  },

  {
    name: "Dr. Kaniz Fatema",

    degrees: "MBBS, DDV",

    lat: 23.7986,

    lng: 90.3849,

    address: "Mirpur General Hospital, Mirpur, Dhaka",
  },

  {
    name: "Dr. Mahbuba Islam",

    degrees: "MBBS, FCPS (Dermatology & Venereology)",

    lat: 23.7572,

    lng: 90.3916,

    address: "Popular Diagnostic Centre, Dhanmondi, Dhaka",
  },

  {
    name: "Dr. Imtiaz Hossain",

    degrees: "MBBS, DDV",

    lat: 23.742,

    lng: 90.3912,

    address: "BIRDEM General Hospital, Shahbagh, Dhaka",
  },

  {
    name: "Dr. Taslima Chowdhury",

    degrees: "MBBS, FCPS (Skin & VD)",

    lat: 23.7465,

    lng: 90.3879,

    address:
      "Holy Family Red Crescent Medical College Hospital, Eskaton, Dhaka",
  },

  {
    name: "Dr. Shahriar Ahmed",

    degrees: "MBBS, DDV, FCPS",

    lat: 23.7617,

    lng: 90.4212,

    address: "International Medical College & Hospital, Tongi, Dhaka",
  },

  {
    name: "Dr. Nusrat Jahan",

    degrees: "MBBS, FCPS (Dermatology)",

    lat: 23.7371,

    lng: 90.3845,

    address: "Anwer Khan Modern Medical College and Hospital, Dhanmondi, Dhaka",
  },

  {
    name: "Dr. Soumik Chowdhury",

    degrees: "MBBS, FCPS (Skin & VD)",

    lat: 23.7612,

    lng: 90.4075,

    address: "Bashundhara Ad-din Medical College and Hospital, Dhaka",
  },

  {
    name: "Dr. Preetom Kumar",

    degrees: "MBBS, DDV",

    lat: 23.7893,

    lng: 90.4049,

    address: "LabAid Diagnostic Centre, Uttara, Dhaka",
  },

  {
    name: "Dr. Sumaiya Khan",

    degrees: "MBBS, FCPS (Skin & VD)",

    lat: 23.7245,

    lng: 90.3957,

    address: "Central Care Hospital, Jigatola, Dhaka",
  },

  {
    name: "Dr. Mamunur Rashid",

    degrees: "MBBS, DDV, FCPS",

    lat: 23.8105,

    lng: 90.4253,

    address: "Al-Helal Specialized Hospital, Mohammadpur, Dhaka",
  },

  {
    name: "Dr. Ashfaque Sayeed",

    degrees: "MBBS, DDV",

    lat: 23.7835,

    lng: 90.4162,

    address: "CARE Hospital, Banani, Dhaka",
  },

  {
    name: "Dr. Nasrin Sultana",

    degrees: "MBBS, FCPS (Dermatology)",

    lat: 23.7994,

    lng: 90.4163,

    address: "Ibn Sina Medical College Hospital, Kallyanpur, Dhaka",
  },

  {
    name: "Dr. Farid Uddin",

    degrees: "MBBS, DDV, MCPS",

    lat: 23.8161,

    lng: 90.4287,

    address: "Al Raji Hospital, Farmgate, Dhaka",
  },

  {
    name: "Dr. Al-men Suravi",

    degrees: "MBBS, FCPS (Skin & VD)",

    lat: 23.7461,

    lng: 90.3737,

    address: "Islami Bank Hospital, Motijheel, Dhaka",
  },

  {
    name: "Dr. Kawser Mahmud",

    degrees: "MBBS, DDV",

    lat: 23.7383,

    lng: 90.3941,

    address: "Bangladesh Medical College Hospital, Dhanmondi, Dhaka",
  },

  {
    name: "Dr. Moumita Das",

    degrees: "MBBS, DDV, FCPS",

    lat: 23.744,

    lng: 90.3979,

    address: "LabAid Specialized Hospital, Gulshan, Dhaka",
  },

  {
    name: "Dr. Mahmod Hasan",

    degrees: "MBBS, FCPS (Dermatology & Venereology)",

    lat: 23.7959,

    lng: 90.4047,

    address: "Shahabuddin Medical College Hospital, Gulshan, Dhaka",
  },

  {
    name: "Dr. Rehana Parveen",

    degrees: "MBBS, DDV",

    lat: 23.7549,

    lng: 90.3778,

    address: "Metropolitan Medical Centre, Panthapath, Dhaka",
  },

  {
    name: "Dr. Khaled Ahmed",

    degrees: "MBBS, FCPS (Skin & VD)",

    lat: 23.7803,

    lng: 90.4147,

    address: "Community Based Medical College Hospital, Moghbazar, Dhaka",
  },

  {
    name: "Dr. Ruhul Amin",

    degrees: "MBBS, DDV",

    lat: 23.7281,

    lng: 90.4006,

    address: "Popular Diagnostic Centre, Shyamoli, Dhaka",
  },

  {
    name: "Dr. Samira Yasmin",

    degrees: "MBBS, FCPS (Dermatology & Venereology)",

    lat: 23.7801,

    lng: 90.4034,

    address: "North End Medical Clinic, Gulshan, Dhaka",
  },

  {
    name: "Dr. Kamal Uddin",

    degrees: "MBBS, DDV",

    lat: 23.748,

    lng: 90.3856,

    address: "Adubon Hospital, Dhanmondi, Dhaka",
  },

  {
    name: "Dr. Minara Rahman",

    degrees: "MBBS, FCPS (Dermatology)",

    lat: 23.7929,

    lng: 90.4148,

    address: "LabAid Diagnostic Centre, Mirpur, Dhaka",
  },

  {
    name: "Dr. Sadia Afrin",

    degrees: "MBBS, FCPS (Skin & VD)",

    lat: 23.7321,

    lng: 90.3994,

    address: "Unique Diagnostic Centre, Farmgate, Dhaka",
  },

  {
    name: "Dr. Mahmud Hossain",

    degrees: "MBBS, DDV",

    lat: 23.7516,

    lng: 90.4259,

    address: "Meditech Diagnostic Centre, Panthapath, Dhaka",
  },

  {
    name: "Dr. Afia Sultana",

    degrees: "MBBS, FCPS (Dermatology & Venereology)",

    lat: 23.7579,

    lng: 90.3908,

    address: "Dhanmondi Diagnostic Center, Dhanmondi, Dhaka",
  },

  {
    name: "Dr. Raihan Al Hasan",

    degrees: "MBBS, DDV",

    lat: 23.7437,

    lng: 90.3862,

    address: "Prime Medical College & Hospital, Dhanmondi, Dhaka",
  },

  {
    name: "Dr. Mariam Chowdhury",

    degrees: "MBBS, FCPS (Skin & VD)",

    lat: 23.7658,

    lng: 90.4234,

    address: "LabAid Diagnostic Centre, Banani, Dhaka",
  },

  {
    name: "Dr. Mahfuzur Rahman",

    degrees: "MBBS, DDV",

    lat: 23.7409,

    lng: 90.4152,

    address: "Samorita Hospital, Panthapath, Dhaka",
  },

  {
    name: "Dr. Shaheen Sultana",

    degrees: "MBBS, FCPS (Dermatology & Venereology)",

    lat: 23.7975,

    lng: 90.4114,

    address: "Northern Diagnostic Centre, Mirpur, Dhaka",
  },

  {
    name: "Dr. Sazzad Hossain",

    degrees: "MBBS, DDV",

    lat: 23.7621,

    lng: 90.3858,

    address: "Al Amin Diagnostic Centre, Dhanmondi, Dhaka",
  },

  {
    name: "Dr. Nasim Anwar",

    degrees: "MBBS, FCPS (Skin & VD)",

    lat: 23.7314,

    lng: 90.3969,

    address: "Universal Medical College Hospital, Mohakhali, Dhaka",
  },

  {
    name: "Dr. Humaira Jahan",

    degrees: "MBBS, DDV",

    lat: 23.7571,

    lng: 90.3974,

    address: "MedLine Diagnostic Centre, Bashundhara, Dhaka",
  },

  {
    name: "Dr. Shafiqul Islam",

    degrees: "MBBS, FCPS (Dermatology)",

    lat: 23.7275,

    lng: 90.4078,

    address: "Osmani Medical College Hospital, Tejgaon, Dhaka",
  },

  {
    name: "Dr. Lutfar Rahman",

    degrees: "MBBS, DDV",

    lat: 23.7703,

    lng: 90.4237,

    address: "AZM Medical Center, Badda, Dhaka",
  },

  {
    name: "Dr. Romana Akter",

    degrees: "MBBS, FCPS (Dermatology & Venereology)",

    lat: 23.7386,

    lng: 90.3993,

    address: "Popular Diagnostic Centre, Mirpur, Dhaka",
  },
];
