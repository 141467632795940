import React,{useState,useEffect} from 'react'
import Navbar from '../Components/Navbar'
import FAQ from '../Components/FAQ'
import { detect } from 'detect-browser'

export default function Home() {
  const [lan,setLan]=useState("bangla")
  const browser=detect()
  useEffect(()=>{
    if(localStorage.getItem("omni-language")==="english"){
      setLan("english")
    }
    console.log(browser)
  })
  return (
    <div className='w-screen min-h-screen  flex flex-col items-center justify-center'>
      <Navbar/>
      <div className='min-h-screen p-8 flex flex-col items-center justify-center text-center'>
       {lan=="english" && <h2 className='text-4xl md:text-7xl font-bold md:mt-12'>Welcome to <span className='text-primary-500'>Omni</span></h2>}
       {lan=="bangla" && <h2 className='text-4xl md:text-7xl font-bold md:mt-12'><span className='text-primary-500'>অমনি </span>তে আপনাকে স্বাগতম </h2>}
        <h2 className='text-lg'>{lan==="english"?"Next generation of menstruation tracker powered by AI":"আর্টিফিশিয়াল ইন্টেলিজেন্স চালিত আগামী প্রজন্মের মাসিক স্বাস্থ এপ্লিকেশন"}</h2>
        
        {window.innerWidth<600 && <div class="relative mx-auto mt-8 border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[600px] w-[300px] shadow-xl">
            <div class="w-[148px] h-[18px] bg-gray-800 top-0 rounded-b-[1rem] left-1/2 -translate-x-1/2 absolute"></div>
            <div class="h-[46px] w-[3px] bg-gray-800 absolute -start-[17px] top-[124px] rounded-s-lg"></div>
            <div class="h-[46px] w-[3px] bg-gray-800 absolute -start-[17px] top-[178px] rounded-s-lg"></div>
            <div class="h-[64px] w-[3px] bg-gray-800 absolute -end-[17px] top-[142px] rounded-e-lg"></div>
            <div class="rounded-[2rem] overflow-hidden w-[272px] h-[572px] bg-white dark:bg-gray-800">
                <img src="https://i.postimg.cc/J0dPp9yZ/image.png"  class="dark:block h-full object-contain" alt=""/>
            </div>
        </div>}
        
        
        {window.innerWidth>=600 && <div class="transform -rotate-90 relative mx-auto border-gray-800 dark:border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[454px] max-w-[341px] md:h-[682px] md:max-w-[512px]">
          <div class="h-[32px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[72px] rounded-s-lg"></div>
          <div class="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[124px] rounded-s-lg"></div>
          <div class="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -start-[17px] top-[178px] rounded-s-lg"></div>
          <div class="h-[64px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -end-[17px] top-[142px] rounded-e-lg"></div>
          <div class="rounded-[2rem] overflow-hidden h-[426px] md:h-[654px] bg-white dark:bg-gray-800">
              <img src="https://i.postimg.cc/PrgMNb1X/image.png" class="w-full rotate-90" alt=""/>
             
          </div>
        </div>}

      </div>
      <div className='min-h-screen w-full p-8 flex flex-col items-center justify-center bg-primary-50'>
        {lan=="english" && <h2 className='text-3xl md:text-5xl text-center font-bold mb-4 '>Omni is a platform for <span className='text-primary-500'>EVERYONE</span></h2>}
        {lan=="bangla" && <h2 className='text-3xl md:text-5xl text-center font-bold mb-4 '>অমনি <span className='text-primary-500'>সবার </span>জন্য একটি প্ল্যাটফর্ম</h2>}
        <div className='grid grid-cols-1 md:grid-cols-3 gap-2'>
          <div class="flex flex-col items-center justify-center max-w-sm p-4 m-2 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
            <img src="https://i.postimg.cc/5tdSph0x/3.png" class="dark:block w-[200px]" alt=""/>
            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{lan==="english"?"For Women":"নারীদের জন্য"}</h5>
            <p class="font-normal text-gray-700 dark:text-gray-400 text-center">{lan==="english"?"Track, Face and Overcome your period":"আপনার মাসিক স্বাস্থ সম্পর্কে জানুন, বুঝুন এবং ট্র্যাক করুন"}</p>
          </div>
          <div class="flex flex-col items-center justify-center max-w-sm p-4 m-2 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
            <img src="https://i.postimg.cc/Cxf4GhwP/4.png" class="dark:block w-[200px]" alt=""/>
            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{lan==="english"?"For Partners":"সঙ্গীর জন্য"}</h5>
            <p class="font-normal text-gray-700 dark:text-gray-400 text-center">{lan==="english"?"Help your partner through her menstruation":"আপনার সঙ্গী কে তার মাসিকের সময় সাপোর্ট করুন"}</p>
          </div>
          
          <div class="flex flex-col items-center justify-center max-w-sm p-4 m-2 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
            <img src="https://i.postimg.cc/GpFJfX8v/5.png" class="dark:block w-[200px]" alt=""/>
            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{lan==="english"?"For Parents":"অভিভাবকদের জন্য"}</h5>
            <p class="font-normal text-gray-700 dark:text-gray-400 text-center">{lan==="english"?"Help your child through menstruation":"আপনার সন্তান কে মাসিকের সময় সাহায্য করুন"}</p>
          </div>
        </div>

      </div>
      {/**HOW */}
      <div className='min-h-screen w-full p-8 flex flex-col items-center justify-center '>
        {lan=="english" && <h2 className='text-3xl md:text-5xl text-center font-bold mb-4 '>How Does OMNI <span className='text-primary-500'>Work?</span></h2>}
        {lan=="bangla" && <h2 className='text-3xl md:text-5xl text-center font-bold mb-4 '>অমনি কীভাবে  <span className='text-primary-500'>কাজ করে?</span></h2>}
        <FAQ/>
      </div>
    </div>
  )
}
