import React,{useState} from 'react'
import { IoClose } from "react-icons/io5";
import { FaHeart } from "react-icons/fa6";
import { IoMdRemove,IoMdAdd } from "react-icons/io";
import { digestionData, moodData, otherData, ovulationTestData, physicalActivityData, pregnancyTestData, sexDriveData, symptomsData, vaginalDischargeData } from '../../Data/SymptomData';
export default function SymptomModal({setOpenPopup,currentDate}) {
    /**
     * 0 = Options
     * 1 = Log Symptoms
     * 2 = Period Start
     * 3 = Period End
     * 4 = Water Intake
     * 5= Weight
     * 6 = Temp
     * 7 = Note
     * 
     */
    const [activeOption,setActiveOption]=useState(0)
  return (
    <div class="overflow-y-scroll overflow-x-hidden fixed bg-gray-100 top-0 left-0  z-50 flex justify-center items-center w-full md:inset-0 h-full max-h-full">
            <div class="relative  p-4 w-full max-w-7xl max-h-full ">
                {/* <!-- Modal content --> */}
                <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    {/* <!-- Modal header --> */}
                    
                    {/* <!-- Modal body --> */}
                    <div class="flex flex-col items-start p-4 md:p-5">
                        <button onClick={()=>setOpenPopup(false)} type="button" class="absolute top-4 right-4 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-lg w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="static-modal">
                            <IoClose/>
                        </button>

                        {activeOption===0 && <div>
                            <h2 className='text-lg md:text-xl mt-4 text-left'>Welcome <span className='font-bold text-primary-500'>{localStorage.getItem("omni-name")?localStorage.getItem("omni-name"):"User"}</span>,</h2>
                            <p className='text-xs md:text-sm text-gray-700 text-left'>Here are some actions that you can perform : </p>
                        </div>}
                        {activeOption===1 && <div>
                            <h2 className='text-lg md:text-xl mt-4 text-left'>Symptom <span className='font-bold text-primary-500'>Categories</span></h2>
                            <p className='text-xs md:text-sm text-gray-700 text-left'>Choose the symptom that you want to log</p>
                        </div>}
                        {activeOption===2 && <div>
                            <h2 className='text-lg md:text-xl mt-4 text-left'>Symptom <span className='font-bold text-primary-500'>Categories</span></h2>
                            <p className='text-xs md:text-sm text-gray-700 text-left'>Choose the symptom that you want to log</p>
                        </div>}
                        {activeOption===3 && <div>
                            <h2 className='text-lg md:text-xl mt-4 text-left'>Symptom <span className='font-bold text-primary-500'>Categories</span></h2>
                            <p className='text-xs md:text-sm text-gray-700 text-left'>Log your daily water intake</p>
                        </div>}
                        {activeOption===4 && <div>
                            <h2 className='text-lg md:text-xl mt-4 text-left'>Log <span className='font-bold text-primary-500'>Water Intake </span></h2>
                            <p className='text-xs md:text-sm text-gray-700 text-left'>Log your daily water intake for today</p>
                        </div>}
                        {activeOption===5 && <div>
                            <h2 className='text-lg md:text-xl mt-4 text-left'>Log <span className='font-bold text-primary-500'>Weight</span></h2>
                            <p className='text-xs md:text-sm text-gray-700 text-left'>Log your body weight for today</p>
                        </div>}
                        {activeOption===6 && <div>
                            <h2 className='text-lg md:text-xl mt-4 text-left'>Log <span className='font-bold text-primary-500'>Body Temperature</span></h2>
                            <p className='text-xs md:text-sm text-gray-700 text-left'>Log your body temperature for today</p>
                        </div>}
                        {activeOption===7 && <div>
                            <h2 className='text-lg md:text-xl mt-4 text-left'>Create <span className='font-bold text-primary-500'>Note</span></h2>
                            <p className='text-xs md:text-sm text-gray-700 text-left'>Add a note about today that you can reflect on later</p>
                        </div>}
                        
                        {activeOption===0 && <div className='w-full grid grid-cols-1 md:grid-cols-4 gap-4 mt-6'> 
                            
                            <div onClick={()=>setActiveOption(1)} className='flex w-full md:max-w-sm md:flex-col items-center justify-between p-4 md:p-6 shadow border border-gray-100 rounded-lg hover:bg-gray-50 cursor-pointer'>
                                <p className='text-xs md:text-md font-semibold'>Log your symptoms</p>
                                <div className=' md:pt-6 md:pb-4'>
                                    <img alt="icon" className='w-8 h-8 md:w-50 md:h-50 m-2' src="https://i.postimg.cc/T3fQs9L8/1x1-Images-4.png"/>
                                </div>
                            </div>
                            <div onClick={()=>setActiveOption(2)} className='flex w-full md:max-w-sm md:flex-col items-center justify-between p-4 md:p-6 shadow border border-gray-100 rounded-lg hover:bg-gray-50 cursor-pointer'>
                                <p className='text-xs md:text-md font-semibold'>Mark Period Start</p>
                                <div className=' md:pt-6 md:pb-4'>
                                    <img alt="icon" className='w-8 h-8 md:w-50 md:h-50 m-2' src="https://i.postimg.cc/QM3nkDs3/1x1-Images.png"/>
                                </div>
                            </div>
                            <div onClick={()=>setActiveOption(3)} className='flex w-full md:max-w-sm md:flex-col items-center justify-between p-4 md:p-6 shadow border border-gray-100 rounded-lg hover:bg-gray-50 cursor-pointer'>
                                <p className='text-xs md:text-md font-semibold'>Mark Period End</p>
                                <div className=' md:pt-6 md:pb-4'>
                                    <img alt="icon" className='w-8 h-8 md:w-50 md:h-50 m-2' src="https://i.postimg.cc/x89cQcKd/1x1-Images-5.png"/>
                                </div>
                            </div>
                            <div onClick={()=>setActiveOption(4)} className='flex w-full md:max-w-sm md:flex-col items-center justify-between p-4 md:p-6 shadow border border-gray-100 rounded-lg hover:bg-gray-50 cursor-pointer'>
                                <p className='text-xs md:text-md font-semibold'>Log Water Intake</p>
                                <div className=' md:pt-6 md:pb-4'>
                                    <img alt="icon" className='w-8 h-8 md:w-50 md:h-50 m-2' src="https://i.postimg.cc/fL1Nsb19/7.png"/>
                                </div>
                            </div>
                            <div onClick={()=>setActiveOption(5)} className='flex w-full md:max-w-sm md:flex-col items-center justify-between p-4 md:p-6 shadow border border-gray-100 rounded-lg hover:bg-gray-50 cursor-pointer'>
                                <p className='text-xs mdtext-md font-semibold'>Log Weight</p>
                                <div className=' md:pt-6 md:pb-4'>
                                    <img alt="icon" className='w-8 h-8 md:w-50 md:h-50 m-2' src="https://i.postimg.cc/W3dvxZYV/6.png"/>
                                </div>
                            </div>
                            <div onClick={()=>setActiveOption(6)} className='flex w-full md:max-w-sm md:flex-col items-center justify-between p-4 md:p-6 shadow border border-gray-100 rounded-lg hover:bg-gray-50 cursor-pointer'>
                                <p className='text-xs md:text-md font-semibold text-left'>Log Basal Temperature</p>
                                <div className=' md:pt-6 md:pb-4'>
                                    <img alt="icon" className='w-8 h-8 md:w-50 md:h-50 m-2' src="https://i.postimg.cc/43NZbqzm/5.png"/>
                                </div>
                            </div>
                            <div onClick={()=>setActiveOption(7)} className='flex w-full md:max-w-sm md:flex-col items-center justify-between p-4 md:p-6 shadow border border-gray-100 rounded-lg hover:bg-gray-50 cursor-pointer'>
                                <p className='text-xs md:text-md font-semibold'>Add Note</p>
                                <div className=' md:pt-6 md:pb-4'>
                                    <img alt="icon" className='w-8 h-8 md:w-50 md:h-50 m-2' src="https://i.postimg.cc/PrYttmXr/4.png"/>
                                </div>
                            </div>
                                

                        </div>}
                        {activeOption===1 && <div className='w-full grid grid-cols-1 gap-4 mt-6'> 
                            {/**MOOD */}
                            <div className='flex w-full flex-col items-start justify-between p-4 md:p-6 shadow border border-gray-100 rounded-lg bg-gray-50 cursor-pointer'>
                                <p className='text-sm md:text-md font-semibold'>Mood</p>
                                <div className='w-full py-4 flex flex-wrap'>
                                    {moodData.map(a=>(
                                        <div className='flex items-center p-1 pr-4 bg-amber-50 cursor-pointer hover:bg-amber-100 rounded-full m-2'>
                                        <div className='p-2 rounded-full bg-amber-300 text-white'><FaHeart/></div>
                                        <p className='text-xs text-left ml-2'>{a.title}</p>
                                    </div> 
                                    ))}
                                </div>
                            </div>
                            {/**SYMPTOMS */}
                            <div className='flex w-full flex-col items-start justify-between p-4 md:p-6 shadow border border-gray-100 rounded-lg bg-gray-50 cursor-pointer'>
                                <p className='text-sm md:text-md font-semibold'>Symptoms</p>
                                <div className='py-4 flex flex-wrap'>
                                    {symptomsData.map(a=>(
                                        <div className='flex items-center p-1 pr-4 bg-indigo-50 cursor-pointer hover:bg-indigo-100 rounded-full m-2'>
                                        <div className='p-2 rounded-full bg-indigo-300 text-white'><FaHeart/></div>
                                        <p className='text-xs text-left ml-2'>{a.title}</p>
                                    </div> 
                                    ))}
                                </div>
                            </div>
                            {/**VAGINAL DISCHARGE */}
                            <div className='flex w-full flex-col items-start justify-between p-4 md:p-6 shadow border border-gray-100 rounded-lg bg-gray-50 cursor-pointer'>
                                <p className='text-sm md:text-md font-semibold'>Vaginal Discharge</p>
                                <div className='py-4 flex flex-wrap'>
                                {vaginalDischargeData.map(a=>(
                                        <div className='flex items-center p-1 pr-4 bg-purple-50 cursor-pointer hover:bg-purple-100 rounded-full m-2'>
                                        <div className='p-2 rounded-full bg-purple-300 text-white'><FaHeart/></div>
                                        <p className='text-xs text-left ml-2'>{a.title}</p>
                                    </div> 
                                    ))}
                                </div>
                            </div>
                            {/**SEX AND SEX DRIVE */}
                            <div className='flex w-full flex-col items-start justify-between p-4 md:p-6 shadow border border-gray-100 rounded-lg bg-gray-50 cursor-pointer'>
                                <p className='text-sm md:text-md font-semibold'>Sex and sex drive</p>
                                <div className='py-4 flex flex-wrap'>
                                {sexDriveData.map(a=>(
                                        <div className='flex items-center p-1 pr-4 bg-primary-50 cursor-pointer hover:bg-primary-100 rounded-full m-2'>
                                        <div className='p-2 rounded-full bg-primary-300 text-white'><FaHeart/></div>
                                        <p className='text-xs text-left ml-2'>{a.title}</p>
                                    </div> 
                                    ))}
                                </div>
                            </div>
                            {/**DIGESTION AND STOOL */}
                            <div className='flex w-full flex-col items-start justify-between p-4 md:p-6 shadow border border-gray-100 rounded-lg bg-gray-50 cursor-pointer'>
                                <p className='text-sm md:text-md font-semibold'>Digestion & Stool</p>
                                <div className='py-4 flex flex-wrap'>
                                {digestionData.map(a=>(
                                        <div className='flex items-center p-1 pr-4 bg-yellow-50 cursor-pointer hover:bg-yellow-100 rounded-full m-2'>
                                        <div className='p-2 rounded-full bg-yellow-300 text-white'><FaHeart/></div>
                                        <p className='text-xs text-left ml-2'>{a.title}</p>
                                    </div> 
                                    ))}
                                </div>
                            </div>
                            {/**PREGNANCY TEST */}
                            <div className='flex w-full flex-col items-start justify-between p-4 md:p-6 shadow border border-gray-100 rounded-lg bg-gray-50 cursor-pointer'>
                                <p className='text-sm md:text-md font-semibold'>Pregnancy Tests</p>
                                <div className='py-4 flex flex-wrap'>
                                {pregnancyTestData.map(a=>(
                                        <div className='flex items-center p-1 pr-4 bg-orange-50 cursor-pointer hover:bg-orange-100 rounded-full m-2'>
                                        <div className='p-2 rounded-full bg-orange-300 text-white'><FaHeart/></div>
                                        <p className='text-xs text-left ml-2'>{a.title}</p>
                                    </div> 
                                    ))}
                                </div>
                            </div>
                            {/**OVULATION TEST */}
                            <div className='flex w-full flex-col items-start justify-between p-4 md:p-6 shadow border border-gray-100 rounded-lg bg-gray-50 cursor-pointer'>
                                <p className='text-sm md:text-md font-semibold'>Ovulation Tests</p>
                                <div className='py-4 flex flex-wrap'>
                                {ovulationTestData.map(a=>(
                                        <div className='flex items-center p-1 pr-4 bg-teal-50 cursor-pointer hover:bg-intealdigo-100 rounded-full m-2'>
                                        <div className='p-2 rounded-full bg-teal-300 text-white'><FaHeart/></div>
                                        <p className='text-xs text-left ml-2'>{a.title}</p>
                                    </div> 
                                    ))}
                                </div>
                            </div>
                            {/**PHYSICAL ACTIVITIES */}
                            <div className='flex w-full flex-col items-start justify-between p-4 md:p-6 shadow border border-gray-100 rounded-lg bg-gray-50 cursor-pointer'>
                                <p className='text-sm md:text-md font-semibold'>Physical Activities</p>
                                <div className='py-4 flex flex-wrap'>
                                    {physicalActivityData.map(a=>(
                                        <div className='flex items-center p-1 pr-4 bg-green-50 cursor-pointer hover:bg-green-100 rounded-full m-2'>
                                        <div className='p-2 rounded-full bg-green-300 text-white'><FaHeart/></div>
                                        <p className='text-xs text-left ml-2'>{a.title}</p>
                                    </div> 
                                    ))} 
                                </div>
                            </div>
                            {/**OTHERS */}
                            <div className='flex w-full flex-col items-start justify-between p-4 md:p-6 shadow border border-gray-100 rounded-lg bg-gray-50 cursor-pointer'>
                                <p className='text-sm md:text-md font-semibold'>Others</p>
                                <div className='py-4 flex flex-wrap'>
                                    {otherData.map(a=>(
                                        <div className='flex items-center p-1 pr-4 bg-blue-50 cursor-pointer hover:bg-blue-100 rounded-full m-2'>
                                        <div className='p-2 rounded-full bg-blue-300 text-white'><FaHeart/></div>
                                        <p className='text-xs text-left ml-2'>{a.title}</p>
                                    </div> 
                                    ))} 
                                </div>
                            </div>
                            {/**Bottom Buttons */}
                            <div onClick={()=>setActiveOption(0)} className='flex flex-col md:flex-row w-full  items-start justify-between p-4 md:p-6 shadow border border-gray-100 rounded-lg bg-gray-50 cursor-pointer'>
                                <button className='w-full my-1 md:my-0 border text-primary-500 hover:text-primary-700 border-primary-500 hover:bg-primary-100 cursor-pointer text-sm font-semibold px-4 py-2 rounded-lg '>
                                    <p>Back</p>
                                </button>
                                <button className='w-full my-1 md:my-0 border text-primary-500 hover:text-primary-700 border-primary-500 hover:bg-primary-100 cursor-pointer text-sm font-semibold px-4 py-2 rounded-lg '>
                                    <p>Log Symptoms</p>
                                </button>
                            </div>
                        </div>}
                        {/**Water Intake */}
                        {activeOption===4 && <div className='w-full grid grid-cols-1 gap-4 mt-6'> 
                           
                            <div className='flex w-full flex-col items-start justify-between p-4 md:p-6 shadow border border-gray-100 rounded-lg bg-gray-50 '>
                                <p className='w-full text-xs md:text-md font-semibold md:text-left'>Water</p>
                               
                                <div className='w-full flex flex-col md:flex-row items-center justify-center md:justify-between py-4'>
                                    <p className='text-md my-2 md:my-0 text-gray-600 text-left'><span className='text-2xl font-bold mr-2'>0</span>/mL</p>
                                    <div >
                                        <button className='p-2 md:p-4 mx-2 rounded-full bg-gray-300 hover:bg-gray-400 '>
                                            <IoMdRemove/>
                                        </button>
                                        <button className='p-2 md:p-4 mx-2 rounded-full bg-gray-300 hover:bg-gray-400 '>
                                            <IoMdAdd/>
                                        </button>
                                    </div>
                                </div>

                               
                            </div>
                            {/**Bottom Buttons */}
                            <div onClick={()=>setActiveOption(0)} className='flex flex-col md:flex-row w-full  items-start justify-between p-4 md:p-6 shadow border border-gray-100 rounded-lg bg-gray-50 cursor-pointer'>
                                <button className='w-full my-1 md:my-0 border text-primary-500 hover:text-primary-700 border-primary-500 hover:bg-primary-100 cursor-pointer text-sm font-semibold px-4 py-2 rounded-lg '>
                                    <p>Back</p>
                                </button>
                                <button className='w-full my-1 md:my-0 border text-primary-500 hover:text-primary-700 border-primary-500 hover:bg-primary-100 cursor-pointer text-sm font-semibold px-4 py-2 rounded-lg '>
                                    <p>Log Water Intake</p>
                                </button>
                            </div>
                        </div>}
                        {/**Log Weight */}
                        {activeOption===5 && <div className='w-full grid grid-cols-1 gap-4 mt-6'> 
                           
                            <div className='flex w-full flex-col items-start md:justify-between p-4 md:p-6 shadow border border-gray-100 rounded-lg bg-gray-50 '>
                                <p className='w-full text-xs md:text-md font-semibold  md:text-left'>Weight</p>
                               
                                <div className='w-full flex flex-col md:flex-row items-center justify-center md:justify-between py-4'>
                                    <p className='text-md my-2 md:my-0 text-gray-600 text-left'><span className='text-2xl font-bold mr-2'>0</span>KG</p>
                                    <div >
                                        <button className='p-2 md:p-4 mx-2 rounded-full bg-gray-300 hover:bg-gray-400 '>
                                            <IoMdRemove/>
                                        </button>
                                        <button className='p-2 md:p-4 mx-2 rounded-full bg-gray-300 hover:bg-gray-400 '>
                                            <IoMdAdd/>
                                        </button>
                                    </div>
                                </div>

                               
                            </div>
                            {/**Bottom Buttons */}
                            <div onClick={()=>setActiveOption(0)} className='flex flex-col md:flex-row w-full  items-start justify-between p-4 md:p-6 shadow border border-gray-100 rounded-lg bg-gray-50 cursor-pointer'>
                                <button className='w-full my-1 md:my-0 border text-primary-500 hover:text-primary-700 border-primary-500 hover:bg-primary-100 cursor-pointer text-sm font-semibold px-4 py-2 rounded-lg '>
                                    <p>Back</p>
                                </button>
                                <button className='w-full my-1 md:my-0 border text-primary-500 hover:text-primary-700 border-primary-500 hover:bg-primary-100 cursor-pointer text-sm font-semibold px-4 py-2 rounded-lg '>
                                    <p>Log Weight</p>
                                </button>
                            </div>
                        </div>}
                        {/**Log Temp */}
                        {activeOption===6 && <div className='w-full grid grid-cols-1 gap-4 mt-6'> 
                           
                            <div className='flex w-full flex-col items-start justify-between p-4 md:p-6 shadow border border-gray-100 rounded-lg bg-gray-50 '>
                                <p className='w-full text-xs md:text-md font-semibold md:text-left'>Body Temperature</p>
                               
                                <div className='w-full flex flex-col md:flex-row items-center justify-center md:justify-between py-4'>
                                    <p className='text-md my-2 md:my-0 text-gray-600 text-left'><span className='text-2xl font-bold mr-2'>0</span>°F</p>
                                    <div >
                                        <button className='p-2 md:p-4 mx-2 rounded-full bg-gray-300 hover:bg-gray-400 '>
                                            <IoMdRemove/>
                                        </button>
                                        <button className='p-2 md:p-4 mx-2 rounded-full bg-gray-300 hover:bg-gray-400 '>
                                            <IoMdAdd/>
                                        </button>
                                    </div>
                                </div>

                               
                            </div>
                            {/**Bottom Buttons */}
                            <div onClick={()=>setActiveOption(0)} className='flex flex-col md:flex-row w-full  items-start justify-between p-4 md:p-6 shadow border border-gray-100 rounded-lg bg-gray-50 cursor-pointer'>
                                <button className='w-full my-1 md:my-0 border text-primary-500 hover:text-primary-700 border-primary-500 hover:bg-primary-100 cursor-pointer text-sm font-semibold px-4 py-2 rounded-lg '>
                                    <p>Back</p>
                                </button>
                                <button className='w-full my-1 md:my-0 border text-primary-500 hover:text-primary-700 border-primary-500 hover:bg-primary-100 cursor-pointer text-sm font-semibold px-4 py-2 rounded-lg '>
                                    <p>Log Temperature</p>
                                </button>
                            </div>
                        </div>}
                        {/**Notes */}
                        {activeOption===7 && <div className='w-full grid grid-cols-1 gap-4 mt-6'> 
                           
                            <div className='flex w-full flex-col items-start justify-between p-4 md:p-6 shadow border border-gray-100 rounded-lg bg-gray-50 '>
                                
                               
                                <div className='w-full flex flex-col items-start py-2'>
                                    <label className='text-left mb-2 text-xs w-full font-semibold'>Note Title</label>
                                    <input type="text" id="simple-search" class="bg-gray-50 border border-gray-300 text-gray-900 text-s rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 text-xs dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Give a title for your note"  />
                                </div>
                                <div className='w-full flex flex-col items-start py-2'>
                                    <label className='text-left mb-2 text-xs w-full font-semibold'>Note Description</label>
                                    <textarea placeholder='Write your note here' className='w-full text-xs rounded-lg border border-gray-100 focus:ring-primary-500 focus:border-primary-500'/>
                                </div>

                               
                            </div>
                            {/**Bottom Buttons */}
                            <div onClick={()=>setActiveOption(0)} className='flex flex-col md:flex-row w-full  items-start justify-between p-4 md:p-6 shadow border border-gray-100 rounded-lg bg-gray-50 cursor-pointer'>
                                <button className='w-full my-1 md:my-0 border text-primary-500 hover:text-primary-700 border-primary-500 hover:bg-primary-100 cursor-pointer text-sm font-semibold px-4 py-2 rounded-lg '>
                                    <p>Back</p>
                                </button>
                                <button className='w-full my-1 md:my-0 border text-primary-500 hover:text-primary-700 border-primary-500 hover:bg-primary-100 cursor-pointer text-sm font-semibold px-4 py-2 rounded-lg '>
                                    <p>Add Note</p>
                                </button>
                            </div>
                        </div>}
                    
                    </div>
                    
                </div>
            </div>
        </div>
  )
}
