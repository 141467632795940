

import { Modal, Button } from "flowbite-react";
import React, { useState } from "react";

import {
  FullscreenControl,
  Map,
  Marker,
  NavigationControl,
} from "react-map-gl";
import 'mapbox-gl/dist/mapbox-gl.css';
import { useNavigate } from "react-router-dom";

// For Production from [https://github.com/alex3165/react-mapbox-gl/issues/938#issuecomment-967789461]
import mapboxgl from 'mapbox-gl';
import Navbar from "../Components/Navbar";
import { doctors } from "../Data/doctorData";
import { SewingPinIcon, StopwatchIcon } from "@radix-ui/react-icons";
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default; //npm install worker-loader
export default function FindDoctors() {

  //console.log(theme.mode)
  const [viewPort, setViewPort] = useState({
    latitude: 23.7461,
    longitude: 90.3742,
    width: 600,
    height: 400,
    zoom: 10,
  });
  const mapThemes={
    "street":"mapbox://styles/mapbox/streets-v12",
    "outdoors":"mapbox://styles/mapbox/outdoors-v12",
    "light":"mapbox://styles/mapbox/light-v11",
    "dark":"mapbox://styles/mapbox/dark-v11",
    "satellite":"mapbox://styles/mapbox/satellite-v9",
    "satellite-street":"mapbox://styles/mapbox/satellite-streets-v12",
    "navigation-day":"mapbox://styles/mapbox/navigation-day-v1",
    "navigation-night":"mapbox://styles/mapbox/navigation-night-v1",
  }
  const [showPopup, setShowPopup] = useState(false);
  const [loading,setLoading]=useState(false)
  const navigator = useNavigate();
  const [currentDoctor,setCurrentDoctor]=useState({
    name: "Dr. Tahmina Banu",
    degrees: "MBBS, FCPS (Dermatology & Venereology)",
    lat: 23.7509,
    lng: 90.3928,
    address: "Square Hospital, Panthapath, Dhaka",
  })
  return (
<div className="h-screen w-screen">
            <Navbar />
        
            {/**MAIN MAP */}
            <Map
                {...viewPort}
                onMove={(view) => setViewPort(view.viewState)}
                mapboxAccessToken={"pk.eyJ1IjoiY29kZXIxOTA1MDc5IiwiYSI6ImNsZHdyMHd6aDBhN3AzdHJ4a2U1aWEzZDMifQ.wllO8_wngV3itTSxhkEngw"}
                mapStyle={ mapThemes["light"] }
            >
                {doctors.map(a=><Marker onClick={()=>{
                    setCurrentDoctor(a)
                    setShowPopup(true)
                }} latitude={a.lat} longitude={a.lng}>
                    <div className="p-2 text-primary-500 hover:bg-primary-500 cursor-pointer rounded-full hover:text-white"><SewingPinIcon width={24} height={24}/></div>
                </Marker>)}
                <NavigationControl position="bottom-right" />
                <FullscreenControl position="bottom-right" />

            </Map>
            <Modal show={showPopup} onClose={() => setShowPopup(false)}>
                <Modal.Header><p className='text-primary-500 font-bold'>Doctor Information</p></Modal.Header>
                <Modal.Body>
                <div className="space-y-6">
                    <p className="text-base  text-black leading-relaxed  dark:text-gray-400">
                    Here are some of the information about this doctor that will help you connect with him/her : 
                    </p>
                    {/**Role */}
                    <div className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    <span className='text-md font-semibold text-black'>Name</span>
                    <p>
                        {currentDoctor.name}
                    </p>
                    </div>
                    {/**Name */}
                    <div className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    <span className='text-md font-semibold  text-black'>Degrees</span>
                    <p>
                    {currentDoctor.degrees}
                    </p>
                    </div>
                    {/**Age */}
                    <div className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    <span className='text-md font-semibold  text-black'>Address</span>
                    <p>
                    {currentDoctor.address}
                    </p>
                    </div>
                   
                </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button className='flex items-center justify-center' color="gray" onClick={() => setShowPopup(false)}>
                    <StopwatchIcon className='mr-2'/>
                    <p>Book an Appointment</p>
                  </Button>
                </Modal.Footer>
            </Modal>
        </div>
  );
}
