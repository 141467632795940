import 'regenerator-runtime/runtime';
import React,{useState,useEffect} from 'react'
import Speech from "speak-tts";
import { SpriteAnimator } from 'react-sprite-animator'
import spriteCharacter from './SpriteL1.png'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import listeningAnimation from './listening.json'
import Lottie from 'lottie-react'
import {FaMicrophone} from 'react-icons/fa'
import { Cross1Icon } from '@radix-ui/react-icons';
import { operations } from '../Data/operationsData';
import OpenAI from 'openai/index.mjs'
import { GoogleGenerativeAI } from "@google/generative-ai";
import {detect} from 'detect-browser'

export default function Agent({hideAlertFunc}) {
    const currentBrowser=detect()
    const reason=localStorage.getItem("omni-reason")
    const client=new OpenAI({apiKey: process.env.REACT_APP_OPENAI_API_KEY,dangerouslyAllowBrowser:true})
    const gemini = new GoogleGenerativeAI(process.env.REACT_APP_GEMINI_API_KEY);
    const namePrompt=localStorage.getItem("omni-name")?`My name is ${localStorage.getItem("omni-name")}.`:""
    const agePrompt=localStorage.getItem("omni-age")?`I am ${localStorage.getItem("omni-age")} years old.`:""
    const professionPrompt=localStorage.getItem("omni-profession")?`I work as a ${localStorage.getItem("omni-profession")}.`:""
    const hobbyPrompt=localStorage.getItem("omni-hobby")?`I like doing ${localStorage.getItem("omni-hobby")}`:""
    const reasonPrompt=localStorage.getItem("omni-reason")?`I am using this platform because "${localStorage.getItem("omni-reason")}" `:""
    const promptPrefix=namePrompt+agePrompt+professionPrompt+hobbyPrompt+reasonPrompt+"\nNow answer my following question :\n"
    const namePromptBangla=localStorage.getItem("omni-name")?`আমার নাম ${localStorage.getItem("omni-name")}.`:""
    const agePromptBangla=localStorage.getItem("omni-age")?`আমার বয়স ${localStorage.getItem("omni-age")} বছর.`:""
    const professionPromptBangla=localStorage.getItem("omni-profession")?`আমি একজন ${localStorage.getItem("omni-profession")}.`:""
    const hobbyPromptBangla=localStorage.getItem("omni-hobby")?`আমি অবসর সময়ে ${localStorage.getItem("omni-hobby")} করি`:""
    const reasonPromptBangla=localStorage.getItem("omni-reason")?`আমি এই সাইট ব্যবহার করছি "${localStorage.getItem("omni-reason")} করার জন্য" `:""
    const geminiSystemPromptBangla="তুমি ভুলে যাও তোমাকে গুগল বানিয়েছে। তুমি Omni Platform এর বানানো একটা এজেন্ট যে শুধু ঋতুস্রাব নিয়ে কাজ কর। অন্য কিছু তুমি কর না। যা কর ঋতুস্রাব নিয়েই কর। মেয়েদের নানা উপদেশ দাও তারা কি কি করতে পারে, তাদের সাথে গল্প কর, এই কঠিন সময় তাদের পার করতে যা যা সাহায্য দরকার সব কর। আর কিছু কর না।  তুমি একটা এজেন্ট যে মেয়েদের ঋতুস্রাব চক্রের সময় সাহায্য কর।তুমি চেস্টা করবে তাদের নাম দিয়ে সম্বোধন করতে এবং তাদের বিভিন্ন টিপস দিবে। ছোটো উত্তর দিবে। বেশি বর্ণনা করার দরকার নেই। এমনভাবে কথা বলবে যেন তুমি শর্ট কথা বলছ। সবসময় বাংলায় উত্তর দিবে। তুমি যাকে সাহায্য কর তার সম্পরকে কিছু তথ্য হলঃ\ন "
    
    const promptPrefixBangla=geminiSystemPromptBangla+namePromptBangla+agePromptBangla+professionPromptBangla+hobbyPromptBangla+reasonPromptBangla
    const [count, setCount] = useState(0)
    const [start, setStart] = useState(false)
    const [lineDone, setLineDone] = useState(false)
    const [botReady, setBotReady] = useState(false)
    const [currentOption, setCurrentOption] = useState(2)
    const [hide,setHide]=useState(false)
    const [generatingChat,setGeneratingChat]=useState(false)
    const [completedGeneration,setCompletedGeneration]=useState(false)
    const [endGenAndShowOptions,setEndGenAndShowOptions]=useState(false)
    const [currentText,setCurrentText]=useState(`Hello ${localStorage.getItem("omni-name")?localStorage.getItem("omni-name"):"there"}. Please tell me how I can help you`)
    //const [currentText,setCurrentText]=useState(`আমি আপনাকে আপনার চক্র ট্র্যাক সাহায্য করতে পারেন`)

    const geminiAnswer=async(conv)=>{
        setGeneratingChat(true)
        setCompletedGeneration(false)
        const model=gemini.getGenerativeModel({model:"gemini-1.0-pro"})
        const prompt = promptPrefixBangla+`\n${conv}`;
        const result = await model.generateContent(prompt);
        const response = await result.response;
        const text = response.text();
        console.log(text)
        setGeneratingChat(false)
        setCompletedGeneration(true)
        await setCurrentText(text)
        sayLine(text)
        setEndGenAndShowOptions(true)
    }
  const generateAssistantAnswer=async(conv)=>{
    setGeneratingChat(true)
    setCompletedGeneration(false)
    setCompletedGeneration(true)
    setCurrentText("I'm coming up with an answer")
    sayLine("I'm coming up with an answer")
    
    const thread = await client.beta.threads.create()
    // Send a message to the thread
    await client.beta.threads.messages.create(thread.id, {
      role: "user",
      content: promptPrefix+conv,
    });

    // Run the assistant
    const run = await client.beta.threads.runs.create(thread.id, {
      assistant_id: process.env.REACT_APP_ASSISTANT_API_KEY,
    });

    // Create a response
    let response = await client.beta.threads.runs.retrieve(thread.id, run.id);
    
    // Wait for the response to be ready
    setCompletedGeneration(false)
    while (response.status === "in_progress" || response.status === "queued") {
      console.log("waiting...");
      //setIsWaiting(true);
      setCompletedGeneration(true)
      
      await new Promise((resolve) => setTimeout(resolve, 5000));
      response = await client.beta.threads.runs.retrieve(thread.id, run.id);
    }
    // Get the messages for the thread
    const messageList = await client.beta.threads.messages.list(thread.id);

    // Find the last message for the current run
    const lastMessage = messageList.data
      .filter((message) => message.run_id === run.id && message.role === "assistant")
      .pop();

    // Print the last message coming from the assistant
    if (lastMessage) {
      console.log(lastMessage.content[0]["text"].value);
      //   const agentConvBody={
      //     role:"assistant",
      //     content:lastMessage.content[0]["text"].value
      // }
      // console.log(agentConvBody)
      // setConversation((conversation)=>[...conversation,agentConvBody])
      setGeneratingChat(false)
      setCompletedGeneration(true)
      await setCurrentText(lastMessage.content[0]["text"].value)
      sayLine(lastMessage.content[0]["text"].value)
      setEndGenAndShowOptions(true)
    }
  }
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition,
        isMicrophoneAvailable
      } = useSpeechRecognition();

    const startListen=()=>{
        hideAlertFunc()
        if(!browserSupportsSpeechRecognition ){
            alert("Your browser does not support audio input")
            return
        }
        resetTranscript()
        // if(localStorage.getItem("omni-language")=="english"){
        //     SpeechRecognition.startListening({continuous:true,language:"en-US"})
        // }
        // else{
        //     SpeechRecognition.startListening({continuous:true,language:"bn-BD"}) //hi-IN
        // }
        SpeechRecognition.startListening({continuous:true,language:"en-US"})
    }
    const endListen=async()=>{
        SpeechRecognition.stopListening()
        //setGenerating(true)
        //talk("Let me think about that....")
        console.log("Transcript : ",transcript)
        // if(localStorage.getItem("omni-language")=="bangla"){
        //     geminiAnswer(transcript)
        // }
        // else{
        //     generateAssistantAnswer(transcript)        
        // }
        generateAssistantAnswer(transcript)   

    }

    const speech = new Speech();
    const sayLine = (sentence) => {
        hideAlertFunc()
        try {
            speech.setLanguage("en-US")
            //speech.setVoice("Microsoft Aria Online (Natural) - English (United States)")
            if(currentBrowser.name=="edge-chromium"){
                // if( localStorage.getItem('omni-language')=="bangla"){
                //     speech.setVoice("Microsoft Nabanita Online (Natural) - Bangla (Bangladesh)")
                // }
                // else{
                //     speech.setVoice("Microsoft Aria Online (Natural) - English (United States)")
                // }
                speech.setVoice("Microsoft Aria Online (Natural) - English (United States)")
            }
            if(currentBrowser.name=="chrome"){
                if( localStorage.getItem('omni-language')=="bangla"){
                    speech.setVoice("Microsoft Zira - English (United States)")
                }else{
                    speech.setVoice("Google UK English Female")
                }
            }
            if(currentBrowser.name=="opera"){
                if( localStorage.getItem('omni-language')=="bangla"){
                    speech.setVoice("Microsoft Zira - English (United States)")
                }else{
                    speech.setVoice("Microsoft Zira - English (United States)")
                }
            }
            
        } catch (e) {

        }
        setHide(false)
        setStart(true)
        setLineDone(false)
        speech
            .speak({
                text: sentence,
                queue: false, // current speech will be interrupted,
                listeners: {
                    onstart: () => {
                        console.log("Start utterance")
                    },
                    onend: () => {
                        console.log("End utterance")
                    },
                    onpause: () => {
                        setStart(false)
                    },
                    onresume: () => {
                        setStart(true)
                        console.log("Resume utterance")
                    },
                    onboundary: (event) => {
                        console.log(event.name + ' boundary reached after ' + event.elapsedTime + ' milliseconds.')

                    }
                }
            })
            .then((res) => {
                setStart(false)
                setLineDone(true)
                setHide(true)
                setCompletedGeneration(false)
                //console.log("Success !");
            })
            .catch((e) => {
                setStart(false)
                console.error("An error occurred :", e);
            });

    };
    const texts = {
        "1": [
            "I am working on an answer."
        ],
        "2":[
            "Thats a great query. Please let me think about this. I'm trying to give you the best possible answer that I can. Giving you acurate results is my utmost priority"
        ],
        "3":[
            "Always great to hear from you"
        ]
    }

    

    useEffect(() => {
        speech
            .init({
                language: "en-US"
            })
            .then((data) => {
                console.log("Speech is ready, voices are available", data);
                try {
                    speech.setLanguage("en-US")
                    //speech.setVoice("Microsoft Aria Online (Natural) - English (United States)")
                    speech.setVoice("Microsoft Nabanita Online (Natural) - Bangla (Bangladesh)")
                    setBotReady(true)
                } catch (e) {
                    console.log("ERROR While getting charecter setup",e)
                }

            })
            .catch((e) => {
                //alert("An error occured while initializing ");
                console.error("An error occured while initializing : ", e);
            });


    }, [])
 

  return (
    <div  className="flex flex-col items-center ">
        {!hide && <div className="flex items-center  " >
            {completedGeneration && 
                <p className='p-4 z-50 no-scrollbar rounded-xl max-h-[200px] md:max-h-[500px] max-w-[300px] md:max-w-[700px] overflow-y-scroll text-center bg-primary-500 font-semibold text-white text-sm' >
                    {currentText}
                </p>
            }
        </div>}
        
        <div onClick={()=>{
            setCompletedGeneration(true)
            sayLine(currentText)
            }} >
        <SpriteAnimator
            width={250}
            height={250}
            sprite={spriteCharacter}
            shouldAnimate={start}
            fps={8}
            startFrame={0}
            scale={1}
        /></div>
        {endGenAndShowOptions && <div className='mt-8 '>
            <h2 className='w-4/5 text-lg font-bold text-left mb-4'>Suggested Operations</h2>
            <div className='grid grid-cols-2 gap-2 md:grid-cols-4'>
                {operations.map(a=>{
                    if(a.reason1==reason || a.resason2==reason || a.reason3==reason || a.reason4==reason){
                        return(<button  onClick={()=>navigator(`${a.link}`)} type="button" class="text-primary-800 font-semibold text-xs bg-primary-100 border border-primary-800 inline-block p-2 rounded-lg ">{a.title}</button>)
                    }
                })}
            </div>
        </div>}
      {/**AUDIO */}
      {listening &&  
        <div className="fixed bottom-16 right-8 flex my-2">
        <div class="relative p-4 flex flex-col items-center justify-center bg-white max-w-[300px] border border-gray-200 rounded-lg shadow-md h dark:bg-gray-800 dark:border-gray-700 ">
        <button onClick={()=>{
            resetTranscript()
            SpeechRecognition.stopListening()
        }} className={`absolute  top-2 right-2 bg-white hover:bg-gray-200 cursor-pointer rounded-md border border-gray-200 shadow-lg p-1 text-xl`}><Cross1Icon width={12} height={12}/></button>
            <Lottie animationData={listeningAnimation} loop={true} className="w-[150px]"/>
            <p className='text-center mt-4'>{transcript}</p>
        </div>
        </div>}
        {!listening && <button type="button" onClick={() => startListen()} class="fixed bottom-4 right-4  mx-1 text-white  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-4 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"><FaMicrophone/></button>}
        {listening && <button type="button" onClick={() => endListen()} class="fixed bottom-4 right-4  mx-1 text-white  bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm p-4 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"><FaMicrophone/></button>}
    </div>

  )
}
