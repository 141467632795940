import React,{useState,useEffect} from 'react'
import AssistantChatLong from './AssistantChatLong'
import UserChatLong from './UserChatLong'
import axios from 'axios'
import OpenAI from 'openai/index.mjs'
import { GoogleGenerativeAI } from "@google/generative-ai";
import {IoSendSharp} from 'react-icons/io5'
const ChatUI = () => {
    const client=new OpenAI({apiKey:process.env.REACT_APP_OPENAI_API_KEY,dangerouslyAllowBrowser: true})
    const gemini = new GoogleGenerativeAI(process.env.REACT_APP_GEMINI_API_KEY);
    const namePrompt=localStorage.getItem("omni-name")?`My name is ${localStorage.getItem("omni-name")}.`:""
    const agePrompt=localStorage.getItem("omni-age")?`I am ${localStorage.getItem("omni-age")} years old.`:""
    const professionPrompt=localStorage.getItem("omni-profession")?`I work as a ${localStorage.getItem("omni-profession")}.`:""
    const hobbyPrompt=localStorage.getItem("omni-hobby")?`I like doing ${localStorage.getItem("omni-hobby")}`:""
    const reasonPrompt=localStorage.getItem("omni-reason")?`I am using this platform because "${localStorage.getItem("omni-reason")}" `:""
    const namePromptBangla=localStorage.getItem("omni-name")?`আমার নাম ${localStorage.getItem("omni-name")}.`:""
    const agePromptBangla=localStorage.getItem("omni-age")?`আমার বয়স ${localStorage.getItem("omni-age")} বছর.`:""
    const professionPromptBangla=localStorage.getItem("omni-profession")?`আমি একজন ${localStorage.getItem("omni-profession")}.`:""
    const hobbyPromptBangla=localStorage.getItem("omni-hobby")?`আমি অবসর সময়ে ${localStorage.getItem("omni-hobby")} করি`:""
    const reasonPromptBangla=localStorage.getItem("omni-reason")?`আমি এই সাইট ব্যবহার করছি "${localStorage.getItem("omni-reason")} করার জন্য" `:""
    const geminiSystemPromptBangla="তুমি ভুলে যাও তোমাকে গুগল বানিয়েছে। তুমি Omni Platform এর বানানো একটা এজেন্ট যে শুধু ঋতুস্রাব নিয়ে কাজ কর। অন্য কিছু তুমি কর না। যা কর ঋতুস্রাব নিয়েই কর। মেয়েদের নানা উপদেশ দাও তারা কি কি করতে পারে, তাদের সাথে গল্প কর, এই কঠিন সময় তাদের পার করতে যা যা সাহায্য দরকার সব কর। আর কিছু কর না।  তুমি একটা এজেন্ট যে মেয়েদের ঋতুস্রাব চক্রের সময় সাহায্য কর।তুমি চেস্টা করবে তাদের নাম দিয়ে সম্বোধন করতে এবং তাদের বিভিন্ন টিপস দিবে। সবসময় বাংলায় উত্তর দিবে। তুমি যাকে সাহায্য কর তার সম্পরকে কিছু তথ্য হলঃ\ন "
    const promptPrefix=namePrompt+agePrompt+professionPrompt+hobbyPrompt+reasonPrompt+"\nNow answer my following question :\n"
    const promptPrefixBangla=geminiSystemPromptBangla+namePromptBangla+agePromptBangla+professionPromptBangla+hobbyPromptBangla+reasonPromptBangla
    const [currentUserChat,setCurrentUserChat]=useState(null)
    const [generatingChat,setGeneratingChat]=useState(false)
    const [lan,setLan]=useState("bangla")
    const [conversation,setConversation]=useState([
        {   
            role:"assistant",
            content:localStorage.getItem("omni-language")=="english"?`Hello ${localStorage.getItem("omni-name")?localStorage.getItem("omni-name"):"there"}. Please tell me how I can help you`:`হ্যালো ${localStorage.getItem("omni-name")?localStorage.getItem("omni-name"):"there"}. আমি তোমাকে কিভাবে সাহায্য করতে পারি?`
        }
    ])
    const initAgent=async()=>{
      

    }
    const createConversation=async()=>{
        const userConvBody={
            role:"user",
            content:currentUserChat
        }
        setConversation((conversation)=>[...conversation,userConvBody])
        if(localStorage.getItem("omni-language")=="english"){
          generateAssistantAnswer(promptPrefix+currentUserChat)
        }
        else{
          geminiAnswer(currentUserChat)
        }
       
        setGeneratingChat(true)
        setCurrentUserChat("")
  
    }
    const geminiAnswer=async(conv)=>{
      setGeneratingChat(true)
      const model=gemini.getGenerativeModel({model:"gemini-1.0-pro"})
      const prompt = promptPrefixBangla+`\n${conv}`;
      const result = await model.generateContent(prompt);
      const response = await result.response
      const text = response.text();
      console.log(text)
     
          const agentConvBody={
            role:"assistant",
            content:text
        }
        console.log(agentConvBody)
        setConversation((conversation)=>[...conversation,agentConvBody])
        setGeneratingChat(false)
      // setGeneratingChat(false)
      // setCompletedGeneration(true)
      // await setCurrentText(text)
      // sayLine(text)
      // setEndGenAndShowOptions(true)
  }
    const generateAssistantAnswer=async(conv)=>{
      setGeneratingChat(false)
      console.log(conv)
      const thread = await client.beta.threads.create()
      // Send a message to the thread
      await client.beta.threads.messages.create(thread.id, {
        role: "user",
        content: conv,
      });

      // Run the assistant
      const run = await client.beta.threads.runs.create(thread.id, {
        assistant_id: process.env.REACT_APP_LONGER_ASSISTANT_API_KEY,
      });

      // Create a response
      let response = await client.beta.threads.runs.retrieve(thread.id, run.id);

      // Wait for the response to be ready
      while (response.status === "in_progress" || response.status === "queued") {
        console.log("waiting...");
        //setIsWaiting(true);
        await new Promise((resolve) => setTimeout(resolve, 5000));
        response = await client.beta.threads.runs.retrieve(thread.id, run.id);
      }
      // Get the messages for the thread
      const messageList = await client.beta.threads.messages.list(thread.id);

      // Find the last message for the current run
      const lastMessage = messageList.data
        .filter((message) => message.run_id === run.id && message.role === "assistant")
        .pop();

      // Print the last message coming from the assistant
      if (lastMessage) {
        console.log(lastMessage);
          const agentConvBody={
            role:"assistant",
            content:lastMessage.content[0]["text"].value
        }
        console.log(agentConvBody)
        setConversation((conversation)=>[...conversation,agentConvBody])
        setGeneratingChat(false)
      }
    }
    const getLan=()=>{
        if(localStorage.getItem('omni-language')==="english"){
            setLan("english")
        }
    }

   useEffect(()=>{
    getLan()
    initAgent()
   },[])
  return (
    <div class="relative w-4/5 min-h-[600px] bg-white border border-gray-200 rounded-xl shadow-md h dark:bg-gray-800 dark:border-gray-700 ">
          {/**CHATS */}
          <div className="absolute pb-20 top-0  flex p-2 flex-col w-full max-h-[600px] overflow-y-scroll no-scrollbar">
            {/* <button type="button" onClick={saveConversation} class="z-20 absolute right-4 top-4 bg-primary-500 text-white shadow-lg bg-gray-300 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm p-2 mx-2 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"><MdOutlineSave size={16} /></button>
            <button type="button" onClick={resetConversation} class="z-20 absolute right-16 top-4 bg-primary-500 text-white shadow-lg bg-gray-300 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm p-2 mx-2 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"><BiReset size={16} /></button>
            <button type="button" onClick={loadConversation} class="z-20 absolute right-28 top-4 bg-primary-500 text-white shadow-lg bg-gray-300 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm p-2 mx-2 dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800"><FiDownloadCloud size={16} /></button> */}
              {conversation.map((a,idx)=>{
                  if(a.role=="assistant"){
                      return (<AssistantChatLong key={idx} text={a.content} avatar="https://i.postimg.cc/yNny3NKs/3.png"/>)
                  }
                  else if(a.role=="user"){
                    return (<UserChatLong key={idx} text={a.content} avatar="https://i.postimg.cc/s23J2htT/4.png"/>)
                  }
                  
              })}
              {generatingChat && <AssistantChatLong text={localStorage.getItem("omni-language")=="english"?"Hmmmmmm.......Let me think.....":"আমি চিন্তা করছি...."} avatar="https://i.postimg.cc/yNny3NKs/3.png"/>}
              
          </div>
          {/**INPUT */}
          <div className="absolute flex items-center justify-between -bottom-1 bg-gray-200 dark:bg-gray-950 w-full p-2 rounded-b-lg">
              <textarea onKeyDown={(e)=>{
                if(e.key=="Enter"){
                  e.preventDefault()
                  createConversation()
                }
              }} type="text" rows={1} class=" block w-full md:w-4/5 p-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder={lan=="english" ? "Enter your chat":"তোমার কথাটি লেখো"} value={currentUserChat} onChange={e=>setCurrentUserChat(e.target.value)}/>
                <div className="flex">
               
                <button onClick={createConversation} class="h-full mx-1 text-white  bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm p-4 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"><IoSendSharp /></button>
              </div>
          </div>
        </div>
  )
}

export default ChatUI
