import React,{useEffect, useState} from 'react'
import {motion} from 'framer-motion'
import logo from './logo.png'
import { Cross1Icon, HamburgerMenuIcon } from '@radix-ui/react-icons'
import { useNavigate } from 'react-router-dom'
export default function Navbar({position="right"}) {
    const navigator=useNavigate()
    const [show,setShow]=useState(false)
    const [lan,setLan]=useState("bangla")

    const getLan=()=>{
        if(localStorage.getItem('omni-language')==="english"){
            setLan("english")
        }
    }
    useEffect(()=>{
        getLan()
    },[])                             
    return (
    <div className='flex flex-col w-full items-center justify-center'>
        {window.innerWidth<600 && <button onClick={()=>setShow(!show)} className={`fixed z-50 top-4 ${position}-4 bg-white hover:bg-gray-200 cursor-pointer rounded-md border border-gray-200 shadow-lg p-2 text-xl`}>{!show?<HamburgerMenuIcon width={22} height={22}/>:<Cross1Icon width={22} height={22}/>}</button>}
        {(show || window.innerWidth>700) && <nav className="fixed top-0 md:top-2 z-30 w-full md:w-3/5 xl:w-1/2 md:flex md:rounded-xl border border-gray-100 shadow-lg items-center justify-between px-4 py-2 bg-white dark:bg-slate-900">
            <div className="p-2 md:px-6"><img src={logo} className=" block w-[30px] h-[30px] md:w-[50px] md:h-[50px]"/></div>
            <motion.p onClick={()=>navigator("/")} whileHover={{scale:1.1}} whileTap={{scale:0.8}} className="px-2 font-semibold cursor-pointer hover:text-primary-600">{lan==="english"?"Home":"হোম"}</motion.p>
            <motion.p onClick={()=>navigator("/calendar")} whileHover={{scale:1.1}} whileTap={{scale:0.8}} className="px-2 font-semibold cursor-pointer hover:text-primary-600">{lan==="english"?"Calender":"ক্যালেন্ডার"}</motion.p>
            <motion.p onClick={()=>navigator("/chitchat")} whileHover={{scale:1.1}} whileTap={{scale:0.8}} className="px-2 font-semibold cursor-pointer hover:text-primary-600">{lan==="english"?"ChitChat":"এজেন্ট"}</motion.p>
            <motion.p onClick={()=>navigator("/faq")} whileHover={{scale:1.1}} whileTap={{scale:0.8}} className="px-2 font-semibold cursor-pointer hover:text-primary-600">{lan==="english"?"FAQs":"প্রশ্নোত্তর"}</motion.p>
            <motion.p onClick={()=>navigator("/shop")} whileHover={{scale:1.1}} whileTap={{scale:0.8}} className="px-2 font-semibold cursor-pointer hover:text-primary-600">{lan==="english"?"Shop":"দোকান"}</motion.p>
            <motion.p onClick={()=>navigator("/doctors")} whileHover={{scale:1.1}} whileTap={{scale:0.8}} className="px-2 font-semibold cursor-pointer hover:text-primary-600">{lan==="english"?"Doctors":"ডাক্তার"}</motion.p>
            <motion.p onClick={()=>navigator("/get-started")} whileHover={{scale:1.1}} whileTap={{scale:0.8}} className="px-5 cursor-pointer hover:bg-primary-500 font-bold hover:text-white py-1.5 border border-gray-200 rounded-xl">{lan==="english"?"Get Started":"শুরু করুন"}</motion.p>
        </nav>}
    </div>
    )
}
