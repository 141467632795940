export const operations=[
    {
      title:"Visit Doctors",
      link:"/doctors",
      reason1:"I have some abnormal symptomps",
      resason2:"I want to conceive",
      reason3:"",
      reason4:"",
    },
    {
      title:"Buy Contraceptives",
      link:"/shop",
      reason1:"I don't want to become pregnant",
      resason2:"",
      reason3:"",
      reason4:"",
    },
    {
      title:"Buy sanitary products",
      link:"/shop",
      reason1:"I want to track my periods",
      resason2:"I have some abnormal symptomps",
      reason3:"I want to conceive",
      reason4:"I don't want to become pregnant",
    },
    {
      title:"View My Cycle Calender",
      link:"/calender",
      reason1:"I want to track my periods",
      resason2:"I have some abnormal symptomps",
      reason3:"I want to conceive",
      reason4:"I don't want to become pregnant",
    },
    {
      title:"Learn more about Omni",
      link:"/faq",
      reason1:"I want to track my periods",
      resason2:"I have some abnormal symptomps",
      reason3:"I want to conceive",
      reason4:"I don't want to become pregnant",
    },

  ]